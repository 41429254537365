import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { SquareX } from 'lucide-react';

import { CountAmount, CountAmountLeft, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn, SearchInput } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Admin() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [loading, setLoading] = useState(true)

    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const [message, setMessage] = useState("")
    const [adminData, setAdminData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])

    useEffect(() => {
        dispatch(change_Current_Location("관리자 조회"))
        dispatch(changeHeaderTitle({
            mainTitle: "관리자 메뉴",
            subTitle: "관리자 조회"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)

        AdminData()
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    const AdminData = async () => {
        await axios.get(process.env.REACT_APP_API + "/admin/list", {
            withCredentials: true,
            params: {

            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setAdminData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "관리자", "width": "200px", "textAlign": "left" },
        { "no": 4, "text": "연락처", "width": "180px", "textAlign": "center" },
        { "no": 5, "text": "삭제여부", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "등록일자", "width": "180px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (adminData) {
            for (let i = 0; i < adminData.length; i++) {
                let data = adminData[i]

                let date = data.createDate.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.id, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": (<div className='pointer' onClick={() => { setUpdateModal(true); setClickData(data) }}>{data.name}</div>), "width": "200px", "textAlign": "left" },
                    { "no": 4, "text": data.mobile, "width": "180px", "textAlign": "center" },
                    {
                        "no": 5, "text": (
                            <div className="flex lg:justify-center items-center">
                                <a className="delete flex items-center text-danger pointer" onClick={() => onDeleteData(data.id)}>
                                    <SquareX width={15} height={15} /> 삭제
                                </a>
                            </div>), "width": "180px", "textAlign": "center"
                    },
                    { "no": 6, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [adminData])

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = combineData.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    const onDeleteData = async (e) => {
        if (window.confirm("관리자데이터를 삭제하시겠습니까?") === true) {
            await axios.post(process.env.REACT_APP_API + "/admin/delete", {
                id: e,
            }, {
                withCredentials: true,
            }).then((response) => {
                console.log(response.data)
                let tmp = response?.data
                if (tmp?.code < 2000) {
                    AdminData()
                }
                else {
                    setMessage({ msg: "관리자 삭제에 실패했습니다." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/admin/list", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createDate.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id,
                    "관리자": t.name,
                    "연락처": t.mobile,
                    "등록일자": `${date[0]} ${date1[0]}`
                })
            }
        }
        Excel(arr)
    }

    // 검색어 포맷
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    // 검색어 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    // 검색 버튼
    const submit = () => {
        console.log('1')
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {createModal ? <CreateInfo setModal={setCreateModal} setMessage={setMessage} /> : ""}
            {updateModal ? <UpdateInfo setModal={setUpdateModal} setMessage={setMessage} clickData={clickData} /> : ""}
            <MessageModal message={message} />
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    {/* {<SearchInput type={"매장선택"} selectedText={selectedText3} handleSelectTextChange={handleSelectTextChange3} />}

                    {<SearchInputBtn type={"검색어"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} onClick={submit} />} */}

                    <div className="button_wrap separate">
                        <SearchCrteateBtn type={"관리자 등록"} onClick={() => setCreateModal(true)} />
                        <SearchExcelBtn type={"엑셀 다운로드"} onClick={() => excelBtn()} />
                    </div>
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <CountAmountLeft tableBodyData={combineData} />
                    <CountSelect onChange={handleChange} maxFirstPage={maxFirstPage} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={combineData} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>
        </div>
    )
}

function AdminInfo({ setModal }) {
    return (
        <div id="admin-modal" className="modal show_modal" tabindex="-1" >
            <input type="hidden" id="event_check" />
            <div className="modal-dialog">
                <div className="modal-content marginTop30 admin-modal-sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto">관리자 정보</h2>
                        <div id="data_wrap">
                            <input type="hidden" id="m_code" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <input id="event_check" type="hidden" />
                        <input id="signup-d-no" type="hidden" data-id="D_NO" />
                        <input id="signup-mcode" type="hidden" data-id="M_CODE" />

                        <div id="merchant-list-wrap" className="col-span-12">
                            <label for="merchant-list" className="form-label">가맹점</label>
                            <div className="merchant-list-box">
                                <select id="merchant-list" className="form-select form-select-sm mt-2"></select>
                            </div>
                        </div>

                        <div className="col-span-12">
                            <label for="signup-id" className="form-label">아이디</label>
                            <input id="signup-id" type="text" className="form-control" data-id="D_UID" placeholder="" />
                        </div>
                        <div className="col-span-12">
                            <label for="signup-pw" className="form-label">패스워드</label>
                            <input id="signup-pw" type="password" autocomplete="off" className="form-control" placeholder="" />

                            <button id="password_update_btn" type="submit" className="btn btn-primary mt-2">비밀번호 변경</button>
                        </div>
                    </form>
                    <div className="col-span-12">
                        <label for="signup-phone" className="form-label">연락처(번호)</label>
                        <input id="signup-phone" type="text" className="form-control" data-id="D_PHONE" placeholder="" />
                    </div>
                    <div className="modal-footer">
                        <button id="signup-btn" type="submit" className="btn btn-primary">등록</button>
                        <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary mr-1 admin-modal-btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CreateInfo({ setModal, setMessage }) {
    const [signupName, setSignupName] = useState('');
    const [signupEmail, setSignupEmail] = useState('');
    const [signupPw, setSignupPw] = useState('');
    const [signupPhone, setSignupPhone] = useState('');
    const [merchantGrade, setMerchantGrade] = useState('SUPER');

    const onSubmit = async () => {
        if (signupName != '' && signupEmail != '' && signupPw != '' && signupPhone != '') {
            await axios.post(process.env.REACT_APP_API + "/admin/create", {
                name: signupName,
                email: signupEmail,
                password: signupPw,
                mobile: signupPhone,
                roleId: merchantGrade == "SUPER" ? 1 : 2
            }, {
                withCredentials: true,
            }).then((response) => {
                console.log(response.data)
                let tmp = response?.data
                if (tmp?.code < 2000) {
                }
                else {
                    setMessage({ msg: "관리자 등록에 실패했습니다." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            setMessage({ msg: "다시 입력해주세요." })
        }
    }

    return (
        <div id="admin-modal" className="modal show_modal" tabindex="-1">
            <input type="hidden" id="event_check" value="update" />
            <div className="modal-dialog">
                <div className="modal-content marginTop30 admin-modal-sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto">관리자 정보</h2>
                        <div id="data_wrap">
                            <input type="hidden" id="m_code" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12">
                            <label htmlFor="signup-id-merchant" className="form-label">관리자</label>
                            <input
                                id="signup-id-merchant"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupName}
                                onChange={(e) => setSignupName(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-id" className="form-label">아이디</label>
                            <input
                                id="signup-id"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupEmail}
                                onChange={(e) => setSignupEmail(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-pw" className="form-label">패스워드</label>
                            <input
                                id="signup-pw"
                                type="password"
                                autoComplete="off"
                                className="form-control"
                                placeholder=""
                                value={signupPw}
                                onChange={(e) => setSignupPw(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-phone" className="form-label">연락처(번호)</label>
                            <input
                                id="signup-phone"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupPhone}
                                onChange={(e) => setSignupPhone(e.target.value)}
                            />
                        </div>

                        <div id="merchant-list-wrap" className="col-span-12">
                            <label htmlFor="merchant-list" className="form-label">등급</label>
                            <div className="merchant-list-box">
                                <select
                                    id="merchant-list"
                                    className="form-select form-select-sm mt-2"
                                    value={merchantGrade}
                                    onChange={(e) => setMerchantGrade(e.target.value)}
                                >
                                    <option value="SUPER">SUPER</option>
                                    <option value="NORMAL">NORMAL</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button id="signup-btn" type="submit" className="btn btn-primary" onClick={() => onSubmit()}>등록</button>
                        <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary mr-1 admin-modal-btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function UpdateInfo({ setModal, setMessage, clickData }) {
    const [signupId, setSignupId] = useState(clickData.id);
    const [signupName, setSignupName] = useState(clickData.name);
    const [signupEmail, setSignupEmail] = useState(clickData.email);
    const [signupPw, setSignupPw] = useState("1234556");
    const [signupPhone, setSignupPhone] = useState(clickData.mobile);
    const [merchantGrade, setMerchantGrade] = useState(clickData.roleId == 1 ? "SUPER" : "NORMAL");

    const onSubmit = async () => {
        if (signupName != '' && signupEmail != '' && signupPw != '' && signupPhone != '') {
            if (window.confirm("수정하시겠습니까?")) {
                onUpdateLevel()
                onUpdateInfo()
                setModal(false)
                // onUpdatePW()
            }
        }
        else {
            setMessage({ msg: "다시 입력해주세요." })
        }
    }

    const onUpdateLevel = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-role", {
            id: signupId,
            roleId: merchantGrade == "SUPER" ? 1 : 2
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }
    const onUpdateInfo = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-contact", {
            id: signupId,
            email: signupEmail,
            mobile: signupPhone,
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }
    const onUpdatePW = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-password", {
            id: signupId,
            oldPassword: "",
            newPassword: signupPw,
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div id="admin-modal" className="modal show_modal" tabindex="-1">
            <input type="hidden" id="event_check" value="update" />
            <div className="modal-dialog">
                <div className="modal-content marginTop30 admin-modal-sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto">관리자 정보</h2>
                        <div id="data_wrap">
                            <input type="hidden" id="m_code" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12">
                            <label htmlFor="signup-id-merchant" className="form-label">가맹점</label>
                            <input
                                id="signup-id-merchant"
                                type="text"
                                className="form-control"
                                placeholder=""
                                disabled="true"
                                value={signupName}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-id" className="form-label">아이디</label>
                            <input
                                id="signup-id"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupEmail}
                                onChange={(e) => setSignupEmail(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-pw" className="form-label">패스워드</label>
                            <input
                                id="signup-pw"
                                type="password"
                                autoComplete="off"
                                className="form-control"
                                placeholder=""
                                disabled="true"
                                value={signupPw}
                                onChange={(e) => setSignupPw(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-phone" className="form-label">연락처(번호)</label>
                            <input
                                id="signup-phone"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupPhone}
                                onChange={(e) => setSignupPhone(e.target.value)}
                            />
                        </div>

                        <div id="merchant-list-wrap" className="col-span-12">
                            <label htmlFor="merchant-list" className="form-label">등급</label>
                            <div className="merchant-list-box">
                                <select
                                    id="merchant-list"
                                    className="form-select form-select-sm mt-2"
                                    value={merchantGrade}
                                    onChange={(e) => setMerchantGrade(e.target.value)}
                                >
                                    <option value="SUPER">SUPER</option>
                                    <option value="NORMAL">NORMAL</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button id="signup-btn" type="submit" className="btn btn-primary" onClick={() => onSubmit()}>수정</button>
                        <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary mr-1 admin-modal-btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `관리자조회`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "가맹점", "연락처", "등록일자"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.관리자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.연락처 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록일자 },
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Admin