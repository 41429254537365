import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

import { changeNum, convertDateFormat } from './common/function';

function SetoffSettlements() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("상계내역"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "상계내역"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
            setup: (picker) => {
                // 이벤트 리스너 등록
                picker.on('selected', (start, end) => {
                    setPreDate(start.format('YYYYMMDD'));
                    setNextDate(end.format('YYYYMMDD'));
                });
            }
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    // 영업자 포맷
    const select1 = ["전체", "지사", "총판", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 상태 포맷
    const select2 = ["상태", "처리 전", "처리 완료"]

    const [selectedValue1, setSelectedValue1] = useState("상태");

    const handleSelectChange1 = (event) => {
        const value = event.target.value;
        setSelectedValue1(value);
    };

    // 검색구분 포맷
    const select4 = ["가맹점명", "TID"]

    const [selectedValue3, setSelectedValue3] = useState("가맹점명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/offset-list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                searchCategory: selectedValue3 == "가맹점명" ? 1 : selectedValue3 == "TID" ? 2 : "",
                searchValue: selectedText3,
                status: selectedValue1 == "처리 전" || "처리 완료" ? selectedValue1 : "",
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                console.log(tmp.data)
                setListData(tmp.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 9, "text": "처리상태", "width": "150px", "textAlign": "center" },
        // { "no": 2, "text": "MCODE", "width": "100px", "textAlign": "center" },
        { "no": 3, "text": "가맹점명", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "TID", "width": "100px", "textAlign": "center" },
        { "no": 8, "text": "상계금액", "width": "130px", "textAlign": "right" },
        { "no": 10, "text": "상계처리일자", "width": "180px", "textAlign": "center" },
        { "no": 5, "text": "승인번호", "width": "180px", "textAlign": "center" },
        // { "no": 4, "text": "주문번호", "width": "200px", "textAlign": "center" },
        { "no": 7, "text": "거래취소일자", "width": "150px", "textAlign": "center" },
        { "no": 6, "text": "거래승인일자", "width": "200px", "textAlign": "center" },
    ];

    const checkBtn = async (id, offset) => {
        if(offset !== "NONE") {
            return;
        }
        await axios.post(process.env.REACT_APP_API + "/settlement/offset/update", {
            id,
        }, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                window.location.reload();
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    function formatDate(dateString) {
        const date = new Date(dateString);

        const yy = date.getFullYear().toString().slice(-2);
        const mm = (date.getMonth() + 1).toString().padStart(2, '0');
        const dd = date.getDate().toString().padStart(2, '0');
        const hh = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');

        return `${yy}-${mm}-${dd} ${hh}:${min}`;
    }


    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i];
                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "80px", "textAlign": "left" },
                    { "no": 9, "text": (<div className='pointer' onClick={() => checkBtn(data.id, data.offset)}>{data.offset === "NONE" ? "처리 전" : "처리 완료"}</div>), "width": "150px", "textAlign": "center" },
                    // { "no": 2, "text": data.user_id, "width": "100px", "textAlign": "center" },
                    { "no": 3, "text": data.franchiseeName, "width": "180px", "textAlign": "left" },
                    { "no": 11, "text": data.cat_id, "width": "100px", "textAlign": "center" },
                    { "no": 8, "text": `${changeNum(Number(data.trx_amount))}원`, "width": "130px", "textAlign": "right" },
                    { "no": 10, "text": `${data.offset === "NONE" ? "-" : formatDate(`${data.updated_at}`)}`, "width": "180px", "textAlign": "center" },
                    { "no": 5, "text": data.tid, "width": "180px", "textAlign": "center" },
                    // { "no": 4, "text": data.order_id, "width": "200px", "textAlign": "left" },
                    { "no": 7, "text": convertDateFormat(`${data.cancel_tran_date} ${data.cancel_tran_time}`), "width": "150px", "textAlign": "center" },
                    { "no": 6, "text": convertDateFormat(data.tran_date + " " + data.tran_time), "width": "200px", "textAlign": "center" },
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/offset-list/excel", {
            withCredentials: true,
            params: {
                searchCategory: selectedValue3 == "가맹점명" ? 1 : selectedValue3 == "TID" ? 2 : "",
                searchValue: selectedText3,
                status: selectedValue1 == "처리 전" || "처리 완료" ? selectedValue1 : "",
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.user_id,
                    "가맹점명": t.franchiseeName,
                    "주문번호": t.order_id,
                    "승인번호": t.tid,
                    "거래승인일자": convertDateFormat(t.tran_date + " " + t.tran_time),
                    "거래취소일자": convertDateFormat(`${t.cancel_tran_date} ${t.cancel_tran_time}`),
                    "상계금액": t.trx_amount,
                    "처리상태": t.offset === "NONE" ? "처리 전" : "처리 완료",
                    "상계일자": t.offset === "NONE" ? "-" : "처리 완료"
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <Info setModal={setModal} /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">

                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {/* {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />} */}

                    {moreBtn && <SearchSelect type={"상태"} selectData={select2} handleSelectChange={handleSelectChange1} selectedValue={selectedValue1} />}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => onSubmitSearch()} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <DownloadBtn text={"상계내역 엑셀"} onClick={excelBtn} />
                    <CountSelect onChange={handleChange} maxFirstPage={maxFirstPage} />
                </div>
                <div className="tabulator_summary_wrap">
                    <CountAmount tableBodyData={listData?.meta?.total ?? 0} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.meta?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>

        </div>
    )
}

function Info({ setModal }) {
    return (
        <div id="alert_modal" className="modal alert_modal show_modal" tabindex="-1" >
            <div className="modal-dialog">
                <h2 className="text-center font-bold text-lg pt-5">상계 완료된 내역입니다.</h2>
                <div className="modal-footer">
                    <button type="button" id="alert_ok_btn" className="btn w-20 btn_ok" onClick={() => setModal(false)}>확인</button>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `상계내역`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "가맹점명", "주문번호", "승인번호", "거래승인일자", "거래취소일자", "상계금액", "처리상태", "상계일자"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.주문번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.거래승인일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.거래취소일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.상계금액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.처리상태 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.상계일자 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default SetoffSettlements