import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './css/calendar.css'
import moment from 'moment';
import { LoadingModal } from './component/modal/modal';
import { change_Current_Location, changeHeaderTitle } from '../store';
import { changeNum } from './common/function';

function SettlementCalendar() {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const today = new Date();
    const [date, setDate] = useState(today);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [listData, setListData] = useState([])
    const [clickData, setClickData] = useState([])

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
    };

    useEffect(() => {
        dispatch(change_Current_Location("정산달력"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "정산달력"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const [value, setValue] = useState(new Date().getFullYear());
    const [holidays, setHolidays] = useState([]);

    useEffect(() => {
        // 공휴일 데이터를 가져오는 함수
        const fetchHolidays = async () => {
            const year = value;
            try {
                const response = await axios.get(
                    'https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo',
                    {
                        params: {
                            solYear: year, // 연도를 지정
                            ServiceKey: process.env.REACT_APP_HOLIDAY_APIKEY, // 발급받은 서비스 키
                            numOfRows: 100, // 한 번에 가져올 데이터 수
                            _type: 'json', // JSON 형식으로 응답 받기
                        },
                    }
                );

                const holidayList = response.data.response.body.items.item.map(item => ({
                    date: item.locdate.toString(), // 날짜를 처리 (YYYYMMDD 형식)
                    name: item.dateName, // 공휴일 이름
                }));

                setHolidays(holidayList); // 공휴일 데이터를 상태에 저장
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, [value]);

    const isHoliday = (date) => {
        return holidays.some(holiday => holiday.date === formatDate(date));
    };

    const getHolidayName = (date) => {
        const holiday = holidays.find(holiday => holiday.date === formatDate(date));
        return holiday ? holiday.name : null;
    };

    // 날짜 포맷을 YYYYMMDD 형식으로 변환하는 함수
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // 월을 2자리로
        const day = (`0${date.getDate()}`).slice(-2); // 일을 2자리로
        return `${year}${month}${day}`;
    };


    useEffect(() => {
        ListData()
    }, [activeStartDate])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/daily", {
            withCredentials: true,
            params: {
                yearMonth: `${activeStartDate.getFullYear()}-${(`0${activeStartDate.getMonth() + 1}`).slice(-2)}`,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    // let arr = [
    //     { "date": "2024.09.01", "check": "Y", "num1": "23905400", "num2": "22376133" },
    //     { "date": "2024.09.02", "check": "Y", "num1": "22248000", "num2": "20740407" },
    //     { "date": "2024.09.03", "check": "Y", "num1": "13254900", "num2": "12361444" },
    //     { "date": "2024.09.04", "check": "Y", "num1": "17619900", "num2": "16727809" },
    //     { "date": "2024.09.05", "check": "Y", "num1": "15502100", "num2": "14543737" },
    //     { "date": "2024.09.06", "check": "Y", "num1": "10256040", "num2": "9734321" },
    //     { "date": "2024.09.07", "check": "Y", "num1": "20835700", "num2": "19517681" },
    //     { "date": "2024.09.08", "check": "Y", "num1": "11009600", "num2": "10414416" },
    //     { "date": "2024.09.09", "check": "Y", "num1": "26460200", "num2": "24852817" },
    //     { "date": "2024.09.10", "check": "Y", "num1": "14380600", "num2": "13503913" },
    //     { "date": "2024.09.11", "check": "Y", "num1": "9163300", "num2": "8648351" },
    //     { "date": "2024.09.12", "check": "Y", "num1": "11199000", "num2": "10532828" },
    //     { "date": "2024.09.13", "check": "Y", "num1": "12387400", "num2": "11659611" },
    //     { "date": "2024.09.14", "check": "Y", "num1": "17578700", "num2": "16530067" },
    //     { "date": "2024.09.15", "check": "Y", "num1": "17566550", "num2": "16572221" },
    //     { "date": "2024.09.16", "check": "Y", "num1": "14375850", "num2": "13630844" },
    //     { "date": "2024.09.17", "check": "Y", "num1": "18814000", "num2": "17752852" },
    //     { "date": "2024.09.18", "check": "Y", "num1": "12458100", "num2": "11804514" },
    //     { "date": "2024.09.19", "check": "Y", "num1": "14614000", "num2": "13536340" },
    //     { "date": "2024.09.20", "check": "Y", "num1": "10551800", "num2": "9868591" },
    //     { "date": "2024.09.21", "check": "Y", "num1": "11880400", "num2": "11214630" },
    //     { "date": "2024.09.22", "check": "Y", "num1": "23725700", "num2": "22404083" },
    //     { "date": "2024.09.23", "check": "N", "num1": "4356500", "num2": "" },
    // ]

    const UpdateData = async (item) => {
        console.log(item.date);
        if (window.confirm("정산하시겠습니까?") === true) {
            await axios.post(process.env.REACT_APP_API + "/settlement/update-check", {
                date: item.date,
                check: 1
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                console.log(tmp)
                if (tmp?.code < 2000) {
                    window.location.reload();
                }
                else if (tmp?.code === 9999) {
                    navigate('/')
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const [screenSize, setScreenSize] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            if (window.innerWidth <= 850) {
                setScreenSize('small');
            } else if (window.innerWidth <= 1200) {
                setScreenSize('medium');
            } else {
                setScreenSize('large');
            }
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const changeNum = (num) => {
        if (typeof num !== 'number' || isNaN(num)) {
            return '0';
        }
      
        if (screenSize === 'small') {
            const kValue = num / 1000;
            if (kValue >= 1000) {
                return `${(kValue / 1000).toFixed(1).replace(/\.0$/, '')}m`;
            }
            const formattedKValue = kValue.toFixed(1).replace(/\.0$/, '');
            return formattedKValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'k';
        } else {
            return num.toLocaleString();
        }
    };

    return (
        <div className="content calendar" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <DetailInfo setModal={setModal} UpdateData={UpdateData} clickData={clickData} /> : ""}
            <div className="intro-y box p-5 mt-5">
                <Calendar
                    value={date}
                    onChange={handleDateChange}
                    formatDay={(locale, date) => moment(date).format('D')}
                    formatYear={(locale, date) => moment(date).format('YYYY')}
                    formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}
                    calendarType="gregory"
                    showNeighboringMonth={true}
                    next2Label={null}
                    prev2Label={null}
                    minDetail="year"

                    // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                    activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                    onActiveStartDateChange={({ activeStartDate }) => {
                        setValue(activeStartDate.getFullYear())
                        setActiveStartDate(activeStartDate)
                    }
                    }

                    // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                    tileContent={({ date, view }) => {
                        let html = [];
                        if (view === 'month' && isHoliday(date)) {
                            html.push(<div className='StyledEX-hoilday'>
                                <div className='StyledEX-holiday'>
                                    <div>{screenSize !== 'small' && getHolidayName(date)}</div>
                                </div>
                            </div>);
                        }
                        if (view === "month") {
                            for (let i = 0; i < listData.length; i++) {
                                let tmp = listData[i]
                                if (tmp.date === moment(date).format("YYYY-MM-DD")) {
                                    html.push(
                                        <div className='StyledEX' onClick={() => { setClickData(tmp); setModal(true) }}>
                                            <div className='StyledEX-holiday StyledEX-3'>
                                                {screenSize === 'large' && <div>매출</div>}
                                                <div>{changeNum(tmp.total_trx)}{screenSize !== 'small' && '원'}</div>
                                            </div>
                                            <div className={`StyledEX-holiday ${tmp.check === 0 ? "StyledEX-1" : "StyledEX-2"}`}>
                                                {screenSize === 'large' && <div>정산</div>}
                                                <div>{changeNum(tmp.total_net)}{screenSize !== 'small' && '원'}</div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        }
                        return <>{html}</>;
                    }}
                />

                {/* 오늘 버튼 추가 */}

                <ul className="calendar-category-wrap">
                    <li className="calendar-category">
                        <span className="dot" style={{ background: "#064e3b" }}></span>
                        <p>매출</p>
                    </li>
                    <li className="calendar-category">
                        <span className="dot" style={{ background: "#1c658c" }}></span>
                        <p>정산완료</p>
                    </li>
                    <li className="calendar-category">
                        <span className="dot" style={{ background: "#e59e2f" }}></span>
                        <p>정산예정</p>
                    </li>
                    <div className='StyledDate' onClick={handleTodayClick}>Today</div>
                </ul>
            </div>
        </div>
    )
}

function DetailInfo({ setModal, UpdateData, clickData }) {
    console.log(clickData);
    return (
        <div id="modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">상세내역</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body" style={{ padding: "0.75rem 0" }}>
                            <div className="detail-form">
                                <table className="tbl-grid">
                                    <tbody>
                                        <tr className="border-btm">
                                            <th>정산일</th>
                                            <td data-id="SETTLEMENT_DATE">{clickData.date}</td>
                                        </tr>
                                        <tr>
                                            <th>승인건수</th>
                                            <td><money data-id="CONFIRM_CNT">{changeNum(Number(clickData.sugi_bill_count))}</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>승인금액</th>
                                            <td><money data-id="CONFIRM_AMT">{changeNum(Number(clickData.sugi_bill_amount))}</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소건수</th>
                                            <td><money data-id="CANCEL_CNT">{changeNum(Number(clickData.sugi_cancel_count))}</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소금액</th>
                                            <td><money data-id="CANCEL_AMT">{changeNum(Number(clickData.sugi_cancel_amount))}</money><span>원</span></td>
                                        </tr>
                                        <tr className="border-btm">
                                            <th>합계</th>
                                            <td><money data-id="TOTAL_AMT">{changeNum(Number(clickData.sugi_bill_amount) - Number(clickData.sugi_cancel_amount))}</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>지급금액</th>
                                            <td><money data-id="SETTLEMENT_AMT">{changeNum(Number(clickData.total_net))}</money><span>원</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        {
                            clickData.check === 1
                                ?
                                <button type="button" className="btn btn-primary w-20 modal_btn" id="settlement_btn">정산완료</button>
                                :
                                <button type="button" className="btn btn-primary w-20 modal_btn" id="settlement_btn" onClick={() => UpdateData(clickData)}>정산</button>
                        }
                        <button type="button" className="btn btn-primary w-20 modal_btn" id="confirm_btn" onClick={() => setModal(false)}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlementCalendar;