import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountAmountLeft, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Agency() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [loading, setLoading] = useState(true)

    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const [message, setMessage] = useState("")
    const [listData, setListData] = useState([])
    const [listData1, setListData1] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    // 검색조건 포맷
    const select3 = ["총판명", "대표자명", "대표자 전화번호"]

    const [selectedValue2, setSelectedValue2] = useState("총판명");

    const handleSelectChange2 = (event) => {
        const value = event.target.value;
        setSelectedValue2(value);
    };

    // 검색어 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    useEffect(() => {
        dispatch(change_Current_Location("총판"))
        dispatch(changeHeaderTitle({
            mainTitle: "매장관리",
            subTitle: "총판"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    useEffect(() => {
        // ListData()
        ListData1()
    }, [reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/sales/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                searchCategory: selectedValue2 == "총판명" ? 1 : selectedValue2 == "대표자명" ? 2 : 3,
                searchValue: selectedText4,
                businessType: 2,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const ListData1 = async () => {
        await axios.get(process.env.REACT_APP_API + "/agent/list", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            setListData1(tmp?.items?.filter(item => item.auth_cd === "02") ?? [])
            // if (tmp?.code < 2000) {
            // }
            // else if (tmp?.code === 9999) {
            //     navigate('/')
            // }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "70px", "textAlign": "left" },
        { "no": 2, "text": "총판명", "width": "150px", "textAlign": "center" },
        { "no": 3, "text": "ID", "width": "150px", "textAlign": "left" },
        { "no": 4, "text": "수수료", "width": "120px", "textAlign": "center" },
        { "no": 5, "text": "정산은행", "width": "120px", "textAlign": "center" },
        { "no": 6, "text": "계좌번호", "width": "170px", "textAlign": "center" },
        { "no": 7, "text": "예금주명", "width": "120px", "textAlign": "center" },
        { "no": 8, "text": "세금계산서이메일", "width": "180px", "textAlign": "left" },
        { "no": 9, "text": "등록일자", "width": "180px", "textAlign": "left" },
        { "no": 10, "text": "변경일자", "width": "180px", "textAlign": "left" },
        { "no": 11, "text": "작업자", "width": "100px", "textAlign": "center" },
        { "no": 12, "text": "수정", "width": "100px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData1) {
            let list = listData1?.slice((page - 1) * maxFirstPage, page * maxFirstPage) ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]
                
                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "70px", "textAlign": "left" },
                    { "no": 2, "text": data.nm, "width": "150px", "textAlign": "center" },
                    { "no": 3, "text": data.agent_id, "width": "150px", "textAlign": "left" },
                    { "no": 4, "text": Number(data.agent_fee), "width": "120px", "textAlign": "center" },
                    { "no": 5, "text": data.bank_nm, "width": "120px", "textAlign": "left" },
                    { "no": 6, "text": data.accnt_no, "width": "170px", "textAlign": "center" },
                    { "no": 7, "text": data.accnt_nm, "width": "120px", "textAlign": "center" },
                    { "no": 8, "text": data.tax_email, "width": "180px", "textAlign": "left" },
                    { "no": 9, "text": data.reg_dt, "width": "180px", "textAlign": "left" },
                    { "no": 10, "text": data.mod_dt, "width": "180px", "textAlign": "left" },
                    { "no": 11, "text": "admin", "width": "100px", "textAlign": "center" },
                    { "no": 12, "text": (<div className='pointer' onClick={() => { setClickData(data); setUpdateModal(true) }}>수정</div>), "width": "100px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData1, page, maxFirstPage])

    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/sales/list/excel", {
            withCredentials: true,
            params: {
                businessType: 2
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createdAt.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id,
                    "총판명": t.name,
                    "구분": "총판",
                    "대표자명": t.representativeName,
                    "전화번호": t.phone1,
                    "은행": t.bankName,
                    "계좌번호": t.accountNumber,
                    "주소": t.address,
                    "등록날짜": `${date[0]} ${date1[0]}`,
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {createModal ? <CreateInfo setModal={setCreateModal} setMessage={setMessage} setReload={setReload} /> : ""}
            {updateModal ? <UpdateInfo setModal={setUpdateModal} setMessage={setMessage} clickData={clickData} setReload={setReload} /> : ""}
            <MessageModal message={message} />
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    {<SearchSelect type={"검색조건"} selectData={select3} handleSelectChange={handleSelectChange2} selectedValue={selectedValue2} />}

                    {<SearchInputBtn type={"검색어"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} onClick={() => setReload(e => !e)} />}

                    <div class="button_wrap separate">
                        <SearchCrteateBtn type={"총판 등록"} onClick={() => setCreateModal(true)} />
                        {/* <SearchExcelBtn type={"엑셀 다운로드"} onClick={() => excelBtn()} /> */}
                    </div>
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <CountAmountLeft tableBodyData={listData1 ?? 0} />
                    <CountSelect onChange={handleChange} maxFirstPage={maxFirstPage} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData1 ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>
        </div>
    )
}

function CreateInfo({ setModal, setMessage, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        총판ID: '',
        총판명: '',
        총판수수료: '',
        지사아이디: '',
        정산은행: '',
        계좌번호: '',
        예금주: '',
        세금계산서Email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=1`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(tmp?.data ?? [])
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        setMessage({ msg: "서비스 준비중입니다." })
        // await axios.post(process.env.REACT_APP_API + "/sales", {
        //     name: formData.NAME,
        //     parentId: formData.PARENT_CODE,
        //     businessTypeId: 2,
        //     representativeName: formData.CEO,
        //     registrationNumber: formData.CEO_SOC_NO,
        //     email: formData.BIZ_EMAIL,
        //     agencyRegistrationNumber: formData.BIZ_SOC_NO,
        //     phone1: formData.HP,
        //     phone2: formData.BIZ_PHONE,
        //     address: formData.BIZ_ADDRESS,
        //     bankName: formData.BANK_CODE,
        //     accountNumber: formData.BANK_NO,
        //     accountHolder: formData.HOLDER_NAME,
        //     feeRate: formData.FEE,
        //     memo: formData.MEMO
        // }, {
        //     withCredentials: true,
        // }).then((response) => {
        //     let tmp = response?.data
        //     if (tmp?.code < 2000) {
        //         setReload(e => !e)
        //         setModal(false)
        //     }
        //     else {
        //         setMessage({ msg: "총판 등록에 실패했습니다." })
        //     }
        // }).catch(error => {
        //     console.error(error);
        // });
    }

    return (
        <div id="distributor-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">총판 정보</h2>

                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: "5px", marginRight: "20px" }}>입력폼 활성화</button> */}

                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판ID" className="form-label">총판ID</label>
                            <input
                                id="총판ID"
                                name="총판ID"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="30"
                                value={formData.총판ID}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판명" className="form-label">총판명</label>
                            <input
                                id="총판명"
                                name="총판명"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.총판명}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판수수료" className="form-label">총판수수료</label>
                            <input
                                id="총판수수료"
                                name="총판수수료"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.총판수수료}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="지사아이디" className="form-label">지사아이디</label>
                            <input
                                id="지사아이디"
                                name="지사아이디"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.지사아이디}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="정산은행" className="form-label">정산은행</label>
                            <input
                                id="정산은행"
                                name="정산은행"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="30"
                                value={formData.정산은행}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="계좌번호" className="form-label">계좌번호</label>
                            <input
                                id="계좌번호"
                                name="계좌번호"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.계좌번호}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="예금주" className="form-label">예금주</label>
                            <input
                                id="예금주"
                                name="예금주"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.예금주}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="세금계산서Email" className="form-label">세금계산서 Email</label>
                            <input
                                id="세금계산서Email"
                                name="세금계산서Email"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="13"
                                value={formData.세금계산서Email}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>등록</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: "darkred", marginLeft: "10px", border: "none" }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function UpdateInfo({ setModal, setMessage, clickData, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        총판ID: '',
        총판명: '',
        총판수수료: '',
        지사아이디: '',
        정산은행: '',
        계좌번호: '',
        예금주: '',
        세금계산서Email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=1`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(tmp?.data ?? [])
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        setMessage({ msg: "서비스 준비중입니다." })
        // if (window.confirm("수정하시겠습니까?")) {
        //     await axios.post(process.env.REACT_APP_API + "/sales/update", {
        //         id: clickData.id,
        //         name: formData.NAME,
        //         parentId: formData.PARENT_CODE,
        //         businessTypeId: 2,
        //         representativeName: formData.CEO,
        //         registrationNumber: formData.CEO_SOC_NO,
        //         email: formData.BIZ_EMAIL,
        //         agencyRegistrationNumber: formData.BIZ_SOC_NO,
        //         phone1: formData.HP,
        //         phone2: formData.BIZ_PHONE,
        //         address: formData.BIZ_ADDRESS,
        //         bankName: formData.BANK_CODE,
        //         accountNumber: formData.BANK_NO,
        //         accountHolder: formData.HOLDER_NAME,
        //         feeRate: formData.FEE,
        //         memo: formData.MEMO
        //     }, {
        //         withCredentials: true,
        //     }).then((response) => {
        //         let tmp = response?.data
        //         if (tmp?.code < 2000) {
        //             setReload(e => !e)
        //             setModal(false)
        //         }
        //         else {
        //             setMessage({ msg: "총판 수정에 실패했습니다." })
        //         }
        //     }).catch(error => {
        //         console.error(error);
        //     });
        // }
    }

    return (
        <div id="distributor-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">총판 정보</h2>

                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: "5px", marginRight: "20px" }}>입력폼 활성화</button> */}

                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판ID" className="form-label">총판ID</label>
                            <input
                                id="총판ID"
                                name="총판ID"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="30"
                                value={formData.총판ID}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판명" className="form-label">총판명</label>
                            <input
                                id="총판명"
                                name="총판명"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.총판명}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="총판수수료" className="form-label">총판수수료</label>
                            <input
                                id="총판수수료"
                                name="총판수수료"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.총판수수료}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="지사아이디" className="form-label">지사아이디</label>
                            <input
                                id="지사아이디"
                                name="지사아이디"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.지사아이디}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="정산은행" className="form-label">정산은행</label>
                            <input
                                id="정산은행"
                                name="정산은행"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="30"
                                value={formData.정산은행}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="계좌번호" className="form-label">계좌번호</label>
                            <input
                                id="계좌번호"
                                name="계좌번호"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.계좌번호}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="예금주" className="form-label">예금주</label>
                            <input
                                id="예금주"
                                name="예금주"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.예금주}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="세금계산서Email" className="form-label">세금계산서 Email</label>
                            <input
                                id="세금계산서Email"
                                name="세금계산서Email"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="13"
                                value={formData.세금계산서Email}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>수정</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: "darkred", marginLeft: "10px", border: "none" }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `총판리스트`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "총판명", "구분", "대표자명", "전화번호", "은행", "계좌번호", "주소", "등록날짜"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대표자명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.전화번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.은행 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.계좌번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.주소 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록날짜 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Agency