import '../../css/app.css'
import '../../css/common.css'
import '../../css/main.css'
import Page from '../../Page';
import React from 'react';
import { useState, useEffect, useRef } from 'react';

function LoadingModal() {
    return (
        <div className="pay_loading_wrap" >
            <div className="pay_loading">
                <img src="/img/dl_logo_white.png" alt="loading" style={{ width: "200px" }} />
            </div>
        </div>
    )
}

function MessageModal({ message }) {
    const [messageModal, setMessageModal] = useState(false)

    useEffect(() => {
        if (message.msg) {
            setMessageModal(true)
        }
    }, [message]);

    
    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setMessageModal(false);
        }
    };
    
    document.addEventListener('keydown', handleEsc);

    return (
        <>
            {
                messageModal
                    ?
                    <div id="alert_modal" class="modal alert_modal" tabindex="-1" >
                        <div class="modal-dialog">
                            <h2 class="text-center font-bold text-lg pt-5">{message?.msg ?? ""}</h2>
                            <div class="modal-footer">
                                <button type="button" id="alert_ok_btn" class="btn w-20 btn_ok" onClick={() => setMessageModal(false)}>확인</button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    )
}

export { LoadingModal, MessageModal }