import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import './css/main.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchInput, SearchInputBtn, SearchExcelBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Product() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [amount, setAmount] = useState([1000000, 50000])
    const [loading, setLoading] = useState(true)
    const [tabBtn, setTabBtn] = useState(0)
    const [modal, setModal] = useState(false)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("단말기 관리"))
        dispatch(changeHeaderTitle({
            mainTitle: "관리자 메뉴",
            subTitle: "단말기 관리"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "가맹점 이름", "width": "180px", "textAlign": "left" },
        { "no": 4, "text": "TID", "width": "120px", "textAlign": "center" },
        { "no": 5, "text": "PG사", "width": "120px", "textAlign": "center" },
        { "no": 6, "text": "종류", "width": "150px", "textAlign": "center" },
        { "no": 7, "text": "시리얼", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "일련번호", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "유심번호", "width": "180px", "textAlign": "center" },
        { "no": 10, "text": "개통번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "만료일자", "width": "180px", "textAlign": "center" },
        { "no": 12, "text": "사용여부", "width": "120px", "textAlign": "center" },
        { "no": 13, "text": "등록날짜", "width": "180px", "textAlign": "center" }
    ];

    const tableHeaderData1 = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "가맹점 이름", "width": "180px", "textAlign": "left" },
        { "no": 4, "text": "TID", "width": "120px", "textAlign": "center" },
        { "no": 5, "text": "PG사", "width": "120px", "textAlign": "center" },
        { "no": 6, "text": "종류", "width": "150px", "textAlign": "center" },
        { "no": 7, "text": "사용여부", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "등록날짜", "width": "180px", "textAlign": "center" }
    ];

    const tableBodyData2 = [
        { "no": 1, "text": "1", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "200282", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "주식회사려원", "width": "180px", "textAlign": "left" },
        { "no": 4, "text": "1046938646", "width": "120px", "textAlign": "center" },
        { "no": 5, "text": "다날", "width": "120px", "textAlign": "center" },
        { "no": 6, "text": "D_LC7212", "width": "150px", "textAlign": "center" },
        { "no": 7, "text": "사용 중", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "2024-09-06 11:45:42", "width": "180px", "textAlign": "center" }
    ];

    const tableBodyData3 = [];

    for (let i = 0; i < 120; i++) {
        tableBodyData3.push(tableBodyData2)
    }

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = combineData.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)
    let filter_tableBodyData1 = tableBodyData3.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)


    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/terminals", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.installationDate.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.franchiseeId, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": (<div className='pointer' onClick={() => setModal(true)}>{data.franchiseeName}</div>), "width": "180px", "textAlign": "left" },
                    { "no": 4, "text": data.tid, "width": "120px", "textAlign": "center" },
                    { "no": 5, "text": data.pgAcquirer, "width": "120px", "textAlign": "center" },
                    { "no": 6, "text": data.modelName, "width": "150px", "textAlign": "center" },
                    { "no": 7, "text": data.serialNumber, "width": "180px", "textAlign": "center" },
                    { "no": 8, "text": data.deviceNumber, "width": "180px", "textAlign": "center" },
                    { "no": 9, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 10, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 11, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 12, "text": "사용 중", "width": "120px", "textAlign": "center" },
                    { "no": 13, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])



    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/terminals", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.installationDate.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.franchiseeId,
                    "가맹점이름": t.franchiseeName,
                    "TID": t.tid,
                    "PG사": t.pgAcquirer,
                    "종류": t.modelName,
                    "시리얼": t.serialNumber,
                    "일련번호": t.deviceNumber,
                    "유심번호": "-",
                    "개통번호": "-",
                    "만료일자": "-",
                    "사용여부": "사용 중",
                    "등록날짜": `${date[0]} ${date1[0]}`
                })
            }
        }
        Excel(arr)
    }


    // 만료일자 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        if (tabBtn == 0) {
            pickerRef.current = new Litepicker({
                element: inputRef.current,
                lang: "ko",
                singleMode: false, // 날짜 범위 선택
                format: 'YYYY/MM/DD', // 날짜 형식
                numberOfMonths: 2, // 두 달 표시
                numberOfColumns: 2, // 두 열로 나누어 표시
                showWeekNumbers: true, // 주 번호 표시
                dropdowns: {
                    minYear: 2020,
                    maxYear: 2030,
                    months: true,
                    years: true,
                },
                setup: (picker) => {
                    // 이벤트 리스너 등록
                    picker.on('selected', (start, end) => {
                        setPreDate(start.format('YYYY/MM/DD'));
                        setNextDate(end.format('YYYY/MM/DD'));
                    });
                }
            });

            return () => {
                pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
            };
        }
    }, [tabBtn]);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    // TID 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };


    // 가맹점명 포맷
    const [selectedText5, setSelectText5] = useState("")

    const handleSelectTextChange5 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <Info setModal={setModal} /> : ""}
            {
                tabBtn == 0
                    ?
                    <>
                        <div className="form_filter intro-y">
                            <form id="tabulator-html-filter-form">
                                <SearchDate type={"만료일자"} inputRef={inputRef} setDateRange={setDateRange} />

                                {<SearchInput type={"TID"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} />}

                                {<SearchInputBtn type={"가맹점명"} selectedText={selectedText5} handleSelectTextChange={handleSelectTextChange5} onClick={() => onSubmitSearch()} />}


                                <div className="button_wrap separate" style={{ marginLeft: "0" }}>
                                    <SearchExcelBtn type={"엑셀 다운로드"} onClick={() => excelBtn()} />
                                </div>
                            </form>
                            <div className="more_wrap">
                                <button type="button" onClick={() => setMoreBtn(e => !e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                                </button>
                            </div>
                        </div>
                        <div className="intro-y box p-5 mt-5">
                            <div className='flex' style={{ width: "100%", height: "36px", border: "1px solid #ebebeb" }}>
                                <div className={`tabBtn ${tabBtn == 0 ? "active" : ""}`} onClick={() => setTabBtn(0)}>단말기</div>
                                <div className={`tabBtn ${tabBtn == 1 ? "active" : ""}`} onClick={() => setTabBtn(1)}>수기</div>
                            </div>
                            <List tableHeaderData={tableHeaderData} tableBodyData={combineData} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} change={tabBtn} />

                            <input type="hidden" id="fromDate" />
                            <input type="hidden" id="toDate" />
                            <input type="hidden" id="date_to_date" />
                        </div>
                    </>
                    :
                    <>
                        <div className="form_filter intro-y">
                            <form id="tabulator-html-filter-form">
                                {<SearchInput type={"TID"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} />}

                                {<SearchInputBtn type={"가맹점명"} selectedText={selectedText5} handleSelectTextChange={handleSelectTextChange5} onClick={() => onSubmitSearch()} />}


                                <div className="button_wrap separate" style={{ marginLeft: "0" }}>
                                    {/* <SearchExcelBtn type={"엑셀 다운로드"} /> */}
                                </div>
                            </form>
                            <div className="more_wrap">
                                <button type="button" onClick={() => setMoreBtn(e => !e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                                </button>
                            </div>
                        </div>
                        <div className="intro-y box p-5 mt-5">
                            <div className='flex' style={{ width: "100%", height: "36px", border: "1px solid #ebebeb" }}>
                                <div className={`tabBtn ${tabBtn == 0 ? "active" : ""}`} onClick={() => setTabBtn(0)}>단말기</div>
                                <div className={`tabBtn ${tabBtn == 1 ? "active" : ""}`} onClick={() => setTabBtn(1)}>수기</div>
                            </div>
                            <List tableHeaderData={tableHeaderData1} tableBodyData={tableBodyData3} filter_tableBodyData={filter_tableBodyData1} page={page1} setPage={setPage1} maxFirstPage={maxFirstPage} change={tabBtn} />

                            <input type="hidden" id="fromDate" />
                            <input type="hidden" id="toDate" />
                            <input type="hidden" id="date_to_date" />
                        </div>
                    </>
            }
        </div>
    )
}

function Info({ setModal }) {
    return (
        <div id="modal" className="modal show_modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">단말기 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg> </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                            <input type="hidden" id="event_check" value="update" />
                            <input type="hidden" id="IDX" data-id="IDX" value="10401" />
                            <div className="col-span-12 sm:col-span-6">
                                <label for="PRODUCT_NAME" className="form-label">단말기 번호</label>
                                <input id="PRODUCT_NAME" type="text" className="form-control" placeholder="1000" data-id="PRODUCT_NAME" />
                            </div>
                            <div className="col-span-12 sm:col-span-6" style={{ display: "block" }}>
                                <label for="M_CODE" className="form-label">가맹점 코드</label>
                                <input id="M_CODE" type="text" className="form-control" placeholder="1000" data-id="M_CODE" readonly="readonly" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="NAME" className="form-label">가맹점 이름</label>
                                <input id="NAME" className="form-select w-full 2xl:w-full mt-2 sm:mt-0" data-id="NAME" />
                            </div>
                            <div className="col-span-12 sm:col-span-6" style={{ display: "block" }}>
                                <label for="REGDATE" className="form-label">등록 날짜</label>
                                <input id="REGDATE" type="text" className="form-control" data-id="REGDATE" readonly="readonly" />
                            </div>
                            <div className="col-span-12 sm:col-span-6" style={{ display: "block" }}>
                                <label for="USE_YN" className="form-label">사용 여부</label><br />
                                <label className="switch">
                                    <input type="checkbox" id="USE_YN" data-id="USE_YN" value="Y" />
                                    <span className="slider round"></span>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 reg_btn">변경</button>
                        <button type="button" className="btn btn-primary w-20 delete_btn">기기 삭제</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `단말기조회`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "가맹점 이름", "TID", "PG사", "종류", "시리얼", "일련번호",
            "유심번호", "개통번호", "만료일자", "사용여부", "등록날짜"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점이름 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.PG사 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.종류 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.시리얼 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.일련번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.유심번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.개통번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.만료일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.사용여부 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록날짜 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Product