const removeZeros = (num) => {
    let strNum = num.toString();
    strNum = strNum.replace(/(\.\d*?)0+$/, "$1");
    strNum = strNum.replace(/\.$/, "");

    return Decimalization(strNum);
}

const Decimalization = (n) => {
    let str = String(n).toUpperCase()
    let arr = str.split('E-')
    let result;
    if (arr[1]) {
        result = (Number(arr[0]) / Math.pow(10, Number(arr[1]))).toFixed(Number(arr[1]) + arr[0].length - 2)
    }
    else {
        result = arr[0]
    }

    return result
}

const convertDateFormat = (dateString) => {
    // 주어진 형식에서 연, 월, 일, 시, 분, 초를 추출
    const year = dateString.slice(2, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hour = dateString.slice(9, 11);
    const minute = dateString.slice(11, 13);
    // const second = dateString.slice(13, 15);

    // 새로운 형식으로 변환
    let result = `${year}-${month}-${day}`;
    if (hour) {
        result += ` ${hour}:${minute}`;
    }

    return result;
};

const convertDate = (dateString) => {
    if (dateString) {
        let date1 = dateString.split('T')
        let date2 = date1[1].split('.000Z')
        return (`${date1[0]} ${date2[0]}`)
    }
    return '';
}

const changeNum = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    // if (arr[1]) {
    //     result = Number(arr[0]).toLocaleString() + "." + arr[1]
    // }
    // else {
    //     result = Number(arr[0]).toLocaleString()
    // }
    result = Number(arr[0]).toLocaleString()

    return result;
}

export { removeZeros, Decimalization, changeNum, convertDateFormat, convertDate }