import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';

function Dashboard() {
    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("대시보드"))
        dispatch(changeHeaderTitle({
            mainTitle: "대시보드",
            subTitle: ""
        }))
    }, [])

    return (
        <div className="flex overflow-hidden">
            {/* <MainModal /> */}
            <div className="content" id="contents">
                <div className="inner-wrap dashboard">
                    <div className="content-container c-left sales">
                        <div className="content-wrap">
                            <div className="content-item">
                                <div className="main-title-wrap">
                                    <h2 className="title">최근 매출액</h2>
                                </div>

                                <div className="panel">
                                    <ul>
                                        <li>
                                            <strong className="truncate calc-title">어제 매출</strong>
                                            <p className="calc-item" id="">
                                                <money id="yesterdayTotalAmount">30,470,100</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                    </ul>
                                    <ul className="em">
                                        <li>
                                            <div className="calc-title east-percent" style={{ display: 'flex' }}>
                                                <strong style={{ display: "contents" }}>오늘 매출</strong>
                                            </div>
                                            <p className="calc-item" id="">
                                                <span>전일대비</span><span className="day_icon" id="todayPer" style={{ margin: "0px 0px 0px 2%", color: "rgb(141, 203, 230)" }}>-85.64%</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevrons-down" className="lucide lucide-chevrons-down day_icon" style={{ margin: "0px 5% 0px 0px", display: "inline", color: "rgb(141, 203, 230)" }} data-lucide="chevrons-down">
                                                    <polyline points="7 13 12 18 17 13"></polyline>
                                                    <polyline points="7 6 12 11 17 6"></polyline>
                                                </svg>
                                                <money id="todayTotalAmount">4,375,500</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-item">
                                <div className="main-title-wrap">
                                    <h2 className="title">월 통계 금액</h2>
                                </div>

                                <div className="panel">
                                    <ul>
                                        <li>
                                            <strong className="truncate calc-title">전월매출</strong>
                                            <p className="calc-item" id="">
                                                <money id="lastMonthTotalAmount">504,802,110</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                        <li>
                                            <strong className="truncate calc-title">승인금액</strong>
                                            <p className="calc-item" id="">
                                                <money id="lastMonthConfirmAmount">525,835,005</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                        <li>
                                            <strong className="truncate calc-title">취소금액</strong>
                                            <p className="calc-item cancelation" id="">
                                                <money id="lastMonthCancelAmount">21,032,895</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                    </ul>
                                    <ul className="em">
                                        <li>
                                            <div className="calc-title east-percent" style={{ display: 'flex' }}>
                                                <strong style={{ display: "contents" }}>당월매출</strong>
                                            </div>
                                            <p className="calc-item east-percent" id="">
                                                <span>전월대비</span><span className="mon_icon" id="monthPer" style={{ margin: "0px 0px 0px 2%", color: "rgb(141, 203, 230)" }}>-9.21%</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevrons-down" className="lucide lucide-chevrons-down mon_icon" style={{ margin: "0px 5% 0px 0px", display: "inline", color: "rgb(141, 203, 230)" }} data-lucide="chevrons-down">
                                                    <polyline points="7 13 12 18 17 13"></polyline>
                                                    <polyline points="7 6 12 11 17 6"></polyline>
                                                </svg>
                                                <money id="thisMonthTotalAmount">458,289,108</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                        <li>
                                            <strong className="calc-title asd2">승인금액</strong>
                                            <p className="calc-item" id="">
                                                <money id="thisMonthConfirmAmount">466,063,616</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                        <li>
                                            <strong className="calc-title">취소금액</strong>
                                            <p className="calc-item cancelation" id="">
                                                <money id="thisMonthCancelAmount">7,774,508</money>
                                                <span>원</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-item">
                                <div className="main-title-wrap intro-y">
                                    <h2 className="title">일별 매출</h2>
                                </div>
                                <div className="panel intro-y">
                                    <div className="dailyStatsChart" style={{width:'100%'}}>
                                        <canvas id="myLineChart" width="455" height="312" style={{ display: "block", boxSizing: "border-box", height: "312px", width: "455px" }}></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="content-container">
                        <div className="content-wrap">
                            <div className="content-item">
                                <div className="main-title-wrap intro-x">
                                    <h2 className="title">공지사항</h2>
                                    <div className="btn-wrap">
                                        <button className="board-left" data-carousel="important-notes" data-target="prev">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-left" data-lucide="chevron-left" className="lucide lucide-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                                        </button>
                                        <button className="board-right" data-carousel="important-notes" data-target="next">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-right" data-lucide="chevron-right" className="lucide lucide-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="panel intro-x">
                                    <div className="notice-wrap tiny-slider" id="important-notes">
                                        <div className="p-5 zoom-in white-back notice_list">
                                            <div id="IDX0" className="text-base font-medium idx" hidden="">10117</div>
                                            <div className="text-base font-medium truncate" style={{ display: "inline-block" }}>2024년8월 무이자할부</div>
                                            <div className="text-slate-400 mt-1">2024-08-02</div>
                                            <div className="text-slate-500 text-justify mt-1 text-ovreflow"></div>
                                            <img id="dash_preview" src="./img/board/2024년8월무이자할부표.png_1722557459205.png" />
                                        </div>
                                        <div className="p-5 zoom-in white-back notice_list">
                                            <div id="IDX1" className="text-base font-medium idx" hidden="">10116</div>
                                            <div className="text-base font-medium truncate" style={{ display: "inline-block" }}>2024년 7월 무이자 할부</div>
                                            <div className="text-slate-400 mt-1">2024-07-05</div>
                                            <div className="text-slate-500 text-justify mt-1 text-ovreflow"></div>
                                            <img id="dash_preview" src="./img/board/제목을-입력해주세요_-001 (29).png_1720166722281.png" />
                                        </div>
                                        <div className="p-5 zoom-in white-back notice_list">
                                            <div id="IDX2" className="text-base font-medium idx" hidden="">10115</div>
                                            <div className="text-base font-medium truncate" style={{ display: "inline-block" }}>2024년 6월 무이자 할부</div>
                                            <div className="text-slate-400 mt-1">2024-06-04</div>
                                            <div className="text-slate-500 text-justify mt-1 text-ovreflow"></div>
                                            <img id="dash_preview" src="./img/board/20246월무이자할부표.png_1717466263955.png" />
                                        </div>
                                        <div className="p-5 zoom-in white-back notice_list">
                                            <div id="IDX3" className="text-base font-medium idx" hidden="">10114</div>
                                            <div className="text-base font-medium truncate" style={{ display: "inline-block" }}>2024년 5월 무이자 할부</div>
                                            <div className="text-slate-400 mt-1">2024-05-08</div>
                                            <div className="text-slate-500 text-justify mt-1 text-ovreflow"></div>
                                            <img id="dash_preview" src="./img/board/제목을-입력해주세요_-001 (27).png_1715134120459.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

function MainModal() {
    return (
        <div id="main_popup_modal" className="modal board_modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty" data-id="TITLE">공지사항 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_close_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown">
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <input type="hidden" id="event_check" />
                    <div className="hidden">
                        <input id="IDX" name="IDX" type="text" className="form-control" data-id="IDX" />
                    </div>
                    {/* <div id="swiper-container" className="swiper-container swiper-initialized swiper-horizontal swiper-backface-hidden">
                        <div className="swiper-wrapper" id="swiper-wrapper-6991d119941dfb5d" aria-live="off">
                            <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 1" style="width: 608px"><img src="/img/board/2024년8월무이자할부표.png_1722557459205.png" /></div>
                        </div>
                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                    </div> */}
                    <div>
                        <input type="checkbox" id="popupCheckBox" />
                        <span>하루동안 표시 안함</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;