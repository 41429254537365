import '../css/app.css'
import '../css/common.css'
import '../css/main.css'
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
    LayoutDashboard, ChevronDown, NotebookPen, CircleDollarSign, Building2, FilePenLine, CalendarCheck, FileMinus, Users, UserRoundX,
    Layers, FileText, FolderPen, UserPlus, Globe, Crown, LocateFixed, Network, UserSearch, Volume2, MonitorSmartphone, PhoneCall, Star, CalendarDays
} from 'lucide-react';
import { useSelector } from 'react-redux';

function Sidebar() {
    let location = useLocation();
    let Current_Location = useSelector((state) => { return state.Current_Location.value });

    const [btn, setBtn] = useState([false, false, false, false, false])
    const [windowtypeM, setWindowtypeM] = useState(false)
    const [cur, setCur] = useState(0)

    const onClickBtn = (e) => {
        let arr = [false, false, false, false, false]
        for (let i = 0; i < 5; i++) {
            if (e === i) {
                if (btn[i]) arr[i] = false
                else arr[i] = true
            }
            else arr[i] = btn[i]
        }
        setBtn(arr)
    }

    const onFirstBtn = (e) => {
        let arr = [false, false, false, false, false]
        for (let i = 0; i < 5; i++) {
            if (e === i) arr[i] = true
        }
        setBtn(arr)
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1280) setWindowtypeM(true)
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (Current_Location) {
            if (Current_Location == "대시보드") { setCur(0); onFirstBtn(-1) }
            else if (Current_Location == "거래내역") { setCur(1); onFirstBtn(0) }
            else if (Current_Location == "거래보류내역") { setCur(2); onFirstBtn(0) }
            else if (Current_Location == "정산달력") { setCur(3); onFirstBtn(1) }
            else if (Current_Location == "정산내역") { setCur(4); onFirstBtn(1) }
            else if (Current_Location == "상계내역") { setCur(5); onFirstBtn(1) }
            else if (Current_Location == "거래별 정산이력") { setCur(6); onFirstBtn(1) }
            else if (Current_Location == "무실적가맹점") { setCur(7); onFirstBtn(1) }
            else if (Current_Location == "정산이력") { setCur(8); onFirstBtn(2) }
            else if (Current_Location == "업체등록") { setCur(9); onFirstBtn(3) }
            else if (Current_Location == "지사") { setCur(10); onFirstBtn(3) }
            else if (Current_Location == "총판") { setCur(11); onFirstBtn(3) }
            else if (Current_Location == "대리점") { setCur(12); onFirstBtn(3) }
            else if (Current_Location == "가맹점") { setCur(13); onFirstBtn(3) }
            else if (Current_Location == "관리자 조회") { setCur(14); onFirstBtn(4) }
            else if (Current_Location == "공지사항") { setCur(15); onFirstBtn(4) }
            else if (Current_Location == "단말기 관리") { setCur(16); onFirstBtn(4) }
            else if (Current_Location == "즐겨찾기 관리") { setCur(17); onFirstBtn(4) }
            else if (Current_Location == "보안 달력") { setCur(18); onFirstBtn(-1) }
        }
    }, [Current_Location])

    return (
        <div className="flex overflow-hidden" style={{ display: location.pathname == '/' ? "none" : "" }}>
            <nav className="side-nav">
                <ul>
                    <li>
                        <Link to={`/dashboard`} className={`side-menu ${cur == 0 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                                <LayoutDashboard />
                            </div>
                            <div className="side-menu__title">
                                <span>대시보드</span>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={`/securityCalendar`} className={`side-menu ${cur == 18 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                                <CalendarDays />
                            </div>
                            <div className="side-menu__title">
                                <span>ISMS 통합 달력</span>
                            </div>
                        </Link>
                    </li> */}
                    <li>
                        <Link className={`side-menu ${cur == 1 || cur == 2 ? "side-menu--active" : ""}`} onClick={() => onClickBtn(0)} >
                            <div className="side-menu__icon">
                                <NotebookPen />
                            </div>
                            <div className="side-menu__title">
                                <span>거래조회</span>
                                <div className="side-menu__sub-icon">
                                    <ChevronDown />
                                </div>
                            </div>
                        </Link>
                        {
                            btn[0]
                                ?
                                <ul className="depth-2">
                                    <li>
                                        <Link to={`/paylist`} className={`sub-side-menu side-menu ${cur == 1 ? "side-menu--active" : ""}`} >
                                            <div className="side-menu__icon">
                                                <FilePenLine />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>거래내역</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/setoffPaylist`} className={`sub-side-menu side-menu ${cur == 2 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <FilePenLine />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>거래보류내역</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                ""
                        }
                    </li>
                    <li>
                        <Link className={`side-menu ${cur == 3 || cur == 4 || cur == 5 || cur == 6 || cur == 7 ? "side-menu--active" : ""}`} onClick={() => onClickBtn(1)} >
                            <div className="side-menu__icon">
                                <CircleDollarSign />
                            </div>
                            <div className="side-menu__title">
                                <span>정산관리</span>
                                <div className="side-menu__sub-icon">
                                    <ChevronDown />
                                </div>
                            </div>
                        </Link>
                        {
                            btn[1]
                                ?
                                <ul className="depth-2">
                                    <li>
                                        <Link to={`/calendar`} className={`side-menu ${cur == 3 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <CalendarCheck />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>정산달력</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/settlements`} className={`side-menu ${cur == 4 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <FilePenLine />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>정산내역</span>
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={`/setoffSettlements`} className={`side-menu ${cur == 5 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <FileMinus />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>상계내역</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/payDetailList`} className={`side-menu ${cur == 6 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <FileText />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>거래별 정산이력</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/unsoldMerchant`} className={`side-menu ${cur == 7 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <UserRoundX />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>무실적가맹점</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                ""
                        }
                    </li>
                    {/* <li>
                        <Link className={`side-menu ${cur == 7 || cur == 8 ? "side-menu--active" : ""}`} onClick={() => onClickBtn(2)} >
                            <div className="side-menu__icon">
                                <FolderPen />
                            </div>
                            <div className="side-menu__title">
                                <span>이력관리</span>
                                <div className="side-menu__sub-icon">
                                    <ChevronDown />
                                </div>
                            </div>
                        </Link>
                        {
                            btn[2]
                                ?
                                <ul className="depth-2">
                                    <li>
                                        <Link to={`/settlementHis`} className={`side-menu ${cur == 8 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Layers />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>정산이력</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                ""
                        }
                    </li> */}

                    <li>
                        <Link className={`side-menu ${cur == 9 || cur == 10 || cur == 11 || cur == 12 || cur == 13 ? "side-menu--active" : ""}`} onClick={() => onClickBtn(3)} >
                            <div className="side-menu__icon">
                                <Building2 />
                            </div>
                            <div className="side-menu__title">
                                <span>매장관리</span>
                                <div className="side-menu__sub-icon">
                                    <ChevronDown />
                                </div>
                            </div>
                        </Link>
                        {
                            btn[3]
                                ?
                                <ul className="depth-2">
                                    {/* <li>
                                        <Link to={`/excelData`} className={`side-menu ${cur == 9 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <UserPlus />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>업체등록</span>
                                            </div>
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to={`/distributor`} className={`side-menu ${cur == 10 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Globe />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>지사</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/agency`} className={`side-menu ${cur == 11 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Crown />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>총판</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/franchise`} className={`side-menu ${cur == 12 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <LocateFixed />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>대리점</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/submerchants`} className={`side-menu ${cur == 13 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Network />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>가맹점</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                ""
                        }
                    </li>
                    <li>
                        <Link className={`side-menu ${cur == 14 || cur == 15 || cur == 16 || cur == 17 ? "side-menu--active" : ""}`} onClick={() => onClickBtn(4)} >
                            <div className="side-menu__icon">
                                <Users />
                            </div>
                            <div className="side-menu__title">
                                <span>관리자 메뉴</span>
                                <div className="side-menu__sub-icon">
                                    <ChevronDown />
                                </div>
                            </div>
                        </Link>
                        {
                            btn[4]
                                ?
                                <ul className="depth-2">
                                    <li>
                                        <Link to={`/admin`} className={`side-menu ${cur == 14 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <UserSearch />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>관리자 조회</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/board`} className={`side-menu ${cur == 15 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Volume2 />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>공지사항</span>
                                            </div>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to={`/product`} className={`side-menu ${cur == 16 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <MonitorSmartphone />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>단말기 관리</span>
                                            </div>
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to={`/favorite`} className={`side-menu ${cur == 17 ? "side-menu--active" : ""}`}>
                                            <div className="side-menu__icon">
                                                <Star />
                                            </div>
                                            <div className="side-menu__title">
                                                <span>즐겨찾기 관리</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                ""
                        }
                    </li>

                    {/* <div className="customer-info">
                        {
                            windowtypeM
                                ?
                                <>
                                    <div className="cust-text">CUSTOMER CENTER</div>
                                    <div className="cust-phone">
                                        <PhoneCall /><span>1600-4406</span>
                                    </div>
                                    <div className="cust-work"><span>평일</span>09:00 - 18:00</div>
                                    <div className="cust-rest"><span>휴무</span>토/일/공휴일 휴무</div>
                                </>
                                :
                                <PhoneCall />
                        }
                    </div> */}
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar;