import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountAmountLeft, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Submerchants() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [loading, setLoading] = useState(true)

    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const [message, setMessage] = useState("")
    const [listData, setListData] = useState([])
    const [listData1, setListData1] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    // 검색조건 포맷
    const select3 = ["가맹점명", "대표자명", "대표자 전화번호"]

    const [selectedValue2, setSelectedValue2] = useState("가맹점명");

    const handleSelectChange2 = (event) => {
        const value = event.target.value;
        setSelectedValue2(value);
    };

    // 검색어 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    // 검색 버튼
    const submit = () => {
        setReload(e => !e)
    }

    useEffect(() => {
        dispatch(change_Current_Location("가맹점"))
        dispatch(changeHeaderTitle({
            mainTitle: "매장관리",
            subTitle: "가맹점"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    useEffect(() => {
        // ListData()
        ListData1()
    }, [reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                searchCategory: selectedValue2 == "가맹점명" ? 1 : selectedValue2 == "대표자명" ? 3 : 4,
                searchValue: selectedText4
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const ListData1 = async () => {
        await axios.get(process.env.REACT_APP_API + "/merchant/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            setListData1(tmp ?? [])
            // if (tmp?.code < 2000) {
            // }
            // else if (tmp?.code === 9999) {
            //     navigate('/')
            // }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "가맹점명", "width": "150px", "textAlign": "center" },
        { "no": 3, "text": "MID", "width": "110px", "textAlign": "center" },
        { "no": 4, "text": "지사", "width": "150px", "textAlign": "center" },
        { "no": 5, "text": "총판", "width": "150px", "textAlign": "left" },
        { "no": 6, "text": "대리점", "width": "150px", "textAlign": "left" },
        { "no": 7, "text": "단말기수수료", "width": "120px", "textAlign": "left" },
        { "no": 8, "text": "카드수수료", "width": "120px", "textAlign": "left" },
        { "no": 9, "text": "정산은행", "width": "150px", "textAlign": "left" },
        { "no": 10, "text": "계좌번호", "width": "150px", "textAlign": "center" },
        { "no": 11, "text": "예금주명", "width": "150px", "textAlign": "center" },
        { "no": 12, "text": "사용여부", "width": "100px", "textAlign": "center" },
        { "no": 13, "text": "등록일자", "width": "180px", "textAlign": "left" },
        { "no": 14, "text": "변경일자", "width": "180px", "textAlign": "left" },
        { "no": 15, "text": "작업자", "width": "100px", "textAlign": "center" },
        { "no": 15, "text": "수정", "width": "100px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData1) {
            let list = listData1?.items?.slice((page - 1) * maxFirstPage, page * maxFirstPage) ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.co_nm, "width": "150px", "textAlign": "center" },
                    { "no": 3, "text": data.mid, "width": "110px", "textAlign": "center" },
                    { "no": 4, "text": data.a_nm, "width": "150px", "textAlign": "center" },
                    { "no": 5, "text": data.b_nm, "width": "150px", "textAlign": "left" },
                    { "no": 6, "text": data.c_nm, "width": "150px", "textAlign": "left" },
                    { "no": 7, "text": data.cid_fee, "width": "120px", "textAlign": "left" },
                    { "no": 8, "text": data.card_fee, "width": "120px", "textAlign": "left" },
                    { "no": 9, "text": data.bank_nm, "width": "150px", "textAlign": "left" },
                    { "no": 10, "text": data.accnt_no, "width": "150px", "textAlign": "center" },
                    { "no": 11, "text": data.accnt_nm, "width": "150px", "textAlign": "center" },
                    { "no": 12, "text": data.use_yn=='Y'?"사용":"미사용", "width": "100px", "textAlign": "center" },
                    { "no": 13, "text": data.reg_dt, "width": "180px", "textAlign": "left" },
                    { "no": 14, "text": data.mod_dt, "width": "180px", "textAlign": "left" },
                    { "no": 15, "text": data.worker, "width": "100px", "textAlign": "center" },
                    { "no": 15, "text": (<div className='pointer' onClick={() => { setClickData(data); setUpdateModal(true) }}>수정</div>), "width": "100px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData1, page, maxFirstPage])

    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/list/excel", {
            withCredentials: true,
            params: {
                searchCategory: selectedValue2 == "가맹점명" ? 1 : selectedValue2 == "대표자명" ? 3 : 5,
                searchValue: selectedText4
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data.items ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createdAt.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id ?? "",
                    "TID": t.tid ?? "",
                    "결제구분": "단말기",
                    "지사명": t.parentId == 1 ? t.name ?? "" : "-",
                    "총판명": t.parentId == 2 ? t.name ?? "" : "-",
                    "대리점명": t.parentId == 3 ? t.name ?? "" : "-",
                    "가맹점명": t.name ?? "",
                    "업종": t.businessCategory ?? "-",
                    "대표자명": t.representativeName ?? "-",
                    "전화번호": t.phone1 ?? "",
                    "은행": t.bankName ?? "",
                    "계좌번호": t.accountNumber ?? "",
                    "주소": t.address ?? "",
                    "등록날짜": `${date[0]} ${date1[0]}` ?? "",
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {createModal ? <CreateInfo setModal={setCreateModal} setMessage={setMessage} setReload={setReload} /> : ""}
            {updateModal ? <UpdateInfo setModal={setUpdateModal} setMessage={setMessage} clickData={clickData} setReload={setReload} /> : ""}
            <MessageModal message={message} />
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    {<SearchSelect type={"검색조건"} selectData={select3} handleSelectChange={handleSelectChange2} selectedValue={selectedValue2} />}

                    {<SearchInputBtn type={"검색어"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} onClick={() => submit()} />}

                    <div class="button_wrap separate">
                        <SearchCrteateBtn type={"가맹점 등록"} onClick={() => setCreateModal(true)} />
                        {/* <SearchExcelBtn type={"엑셀 다운로드"} onClick={() => excelBtn()} /> */}
                    </div>
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <CountAmountLeft tableBodyData={listData1?.items ?? 0} />
                    <CountSelect onChange={handleChange} maxFirstPage={maxFirstPage} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData1?.items ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>
        </div>
    )
}

function CreateInfo({ setModal, setMessage, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        상위업체: '',
        사업자구분: '',
        사업자등록번호: '',
        상호명: '',
        대표자명: '',
        연락처: '',
        사업장주소: '',
        가맹점ID: '',
        수수료계산기준: '',
        수수료등록기준: '',
        정산은행: '',
        취소기능: '',
        예금주: '',
        계좌번호: '',
        세금계산서Email: '',
        부가세표기: '',
        결제공지: '',
        상태: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=0`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(
                    (tmp?.data ?? []).sort((a, b) => a.level - b.level)
                );
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        setMessage({ msg: "서비스 준비중입니다." })
        // await axios.post(process.env.REACT_APP_API + "/franchisee/create", {
        //     name: formData.NAME,
        //     parentId: formData.PARENT_CODE,
        //     registrationNumber: formData.CEO_SOC_NO,
        //     representativeName: formData.CEO,
        //     businessCategory: formData.BIZ_GUBUN == "사업자" ? 2 : 1,
        //     email: formData.BIZ_EMAIL,
        //     phone1: formData.HP,
        //     phone2: formData.BIZ_PHONE,
        //     address: formData.BIZ_ADDRESS,
        //     bankName: formData.BANK_CODE,
        //     accountNumber: formData.BANK_NO,
        //     tid: formData.CAT_ID,
        //     settlementDay: Number(formData.CAL_DATE),
        //     feeRate: formData.FEE,
        //     memo: formData.MEMO
        // }, {
        //     withCredentials: true,
        // }).then((response) => {
        //     let tmp = response?.data
        //     if (tmp?.code < 2000) {
        //         setReload(e => !e)
        //         setModal(false)
        //     }
        //     else {
        //         setMessage({ msg: "가맹점 등록에 실패했습니다." })
        //     }
        // }).catch(error => {
        //     console.error(error);
        // });
    }

    return (
        <div id="submerchants-modal" class="modal show_modal" tabindex="-1">
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">가맹점 정보</h2>
                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: '5px', marginRight: '20px' }}>
                            입력폼 활성화
                        </button> */}
                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form" style={{ height: '57vh', overflowY: 'auto' }} className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        {/* <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="상위업체" className="form-label">영업자</label>
                            <select
                                id="상위업체"
                                name="상위업체"
                                className="form-select w-full"
                                value={formData.상위업체}
                                onChange={handleChange}
                            >
                                <option value={0}>상위업체 선택해주세요.</option>
                                {levelData.map((a) => (
                                    <option key={a.id} value={a.id}>
                                        ({a.level === 1 ? '지사' : a.level === 2 ? '총판' : '대리점'}){a.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="사업자구분" className="form-label">사업자 구분</label>
                            <select
                                id="사업자구분"
                                name="사업자구분"
                                className="form-select w-full"
                                value={formData.사업자구분}
                                onChange={handleChange}
                            >
                                <option value="사업자">사업자</option>
                                <option value="비사업자">비사업자</option>
                            </select>
                        </div> */}

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="사업자등록번호" className="form-label">사업자등록번호</label>
                            <input
                                id="사업자등록번호"
                                name="사업자등록번호"
                                type="text"
                                className="form-control"
                                value={formData.사업자등록번호}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="상호명" className="form-label">상호명</label>
                            <input
                                id="상호명"
                                name="상호명"
                                type="text"
                                className="form-control"
                                value={formData.상호명}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="대표자명" className="form-label">*대표자명</label>
                            <input
                                id="대표자명"
                                name="대표자명"
                                type="text"
                                className="form-control"
                                value={formData.대표자명}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="연락처" className="form-label">*연락처</label>
                            <input
                                id="연락처"
                                name="연락처"
                                type="text"
                                className="form-control"
                                value={formData.연락처}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="사업장주소" className="form-label">사업장주소</label>
                            <input
                                id="사업장주소"
                                name="사업장주소"
                                type="text"
                                className="form-control"
                                value={formData.사업장주소}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="가맹점ID" className="form-label">*가맹점ID</label>
                            <input
                                id="가맹점ID"
                                name="가맹점ID"
                                type="text"
                                className="form-control"
                                value={formData.가맹점ID}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="상태" className="form-label">상태</label>
                            <select
                                id="상태"
                                name="상태"
                                className="form-select w-full"
                                value={formData.상태}
                                onChange={handleChange}
                            >
                                <option value="1">중지</option>
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="수수료계산기준" className="form-label">수수료 계산기준</label>
                            <input
                                id="수수료계산기준"
                                name="수수료계산기준"
                                type="text"
                                className="form-control"
                                value={formData.수수료계산기준}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="수수료등록기준" className="form-label">수수료 등록기준</label>
                            <input
                                id="수수료등록기준"
                                name="수수료등록기준"
                                type="text"
                                className="form-control"
                                value={formData.수수료등록기준}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="정산은행" className="form-label">정산은행</label>
                            <select
                                id="정산은행"
                                name="정산은행"
                                className="form-select w-full"
                                value={formData.정산은행}
                                onChange={handleChange}
                            >
                                <option value="001">한국은행</option>
                                <option value="002">산업은행</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="취소기능" className="form-label">취소기능</label>
                            <input
                                id="취소기능"
                                name="취소기능"
                                type="text"
                                className="form-control"
                                value={formData.취소기능}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="예금주" className="form-label">예금주</label>
                            <input
                                id="예금주"
                                name="예금주"
                                type="text"
                                className="form-control"
                                value={formData.예금주}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="계좌번호" className="form-label">계좌번호</label>
                            <input
                                id="계좌번호"
                                name="계좌번호"
                                type="text"
                                className="form-control"
                                value={formData.계좌번호}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="세금계산서Email" className="form-label">세금계산서 Email</label>
                            <input
                                id="세금계산서Email"
                                name="세금계산서Email"
                                type="text"
                                className="form-control"
                                value={formData.세금계산서Email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="부가세표기" className="form-label">부가세표기</label>
                            <input
                                id="부가세표기"
                                name="부가세표기"
                                type="text"
                                className="form-control"
                                value={formData.부가세표기}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="결제공지" className="form-label">결제공지</label>
                            <input
                                id="결제공지"
                                name="결제공지"
                                type="text"
                                className="form-control"
                                value={formData.결제공지}
                                onChange={handleChange}
                            />
                        </div>
                    </form>


                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>등록</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: 'darkred', marginLeft: '10px', border: 'none' }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function UpdateInfo({ setModal, setMessage, clickData, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        상위업체: '',
        사업자구분: '',
        사업자등록번호: '',
        상호명: '',
        대표자명: '',
        연락처: '',
        사업장주소: '',
        가맹점ID: '',
        수수료계산기준: '',
        수수료등록기준: '',
        정산은행: '',
        취소기능: '',
        예금주: '',
        계좌번호: '',
        세금계산서Email: '',
        부가세표기: '',
        결제공지: '',
        상태: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=0`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(
                    (tmp?.data ?? []).sort((a, b) => a.level - b.level)
                );
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        setMessage({ msg: "서비스 준비중입니다." })
        // if (window.confirm("수정하시겠습니까?")) {
        //     await axios.post(process.env.REACT_APP_API + "/franchisee/update", {
        //         id: clickData.id,
        //         name: formData.NAME,
        //         parentId: formData.PARENT_CODE,
        //         registrationNumber: formData.CEO_SOC_NO,
        //         representativeName: formData.CEO,
        //         businessCategory: formData.BIZ_GUBUN == "사업자" ? 2 : 1,
        //         email: formData.BIZ_EMAIL,
        //         phone1: formData.HP,
        //         phone2: formData.BIZ_PHONE,
        //         address: formData.BIZ_ADDRESS,
        //         bankName: formData.BANK_CODE,
        //         accountNumber: formData.BANK_NO,
        //         tid: formData.CAT_ID,
        //         settlementDay: Number(formData.CAL_DATE),
        //         feeRate: formData.FEE,
        //         memo: formData.MEMO
        //     }, {
        //         withCredentials: true,
        //     }).then((response) => {
        //         let tmp = response?.data
        //         console.log(tmp)
        //         if (tmp?.code < 2000) {
        //             setReload(e => !e)
        //             setModal(false)
        //         }
        //         else {
        //             setMessage({ msg: "가맹점 수정에 실패했습니다." })
        //         }
        //     }).catch(error => {
        //         console.error(error);
        //     });
        // }
    }

    return (
        <div id="submerchants-modal" class="modal show_modal" tabindex="-1">
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">가맹점 정보</h2>
                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: '5px', marginRight: '20px' }}>
                            입력폼 활성화
                        </button> */}
                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form" style={{ height: '57vh', overflowY: 'auto' }} className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <input type="hidden" id="event_check" name="event_check" value="insert" readOnly />
                        <input type="hidden" id="M_CODE" name="M_CODE" readOnly />
                        <input type="hidden" id="GUBUN" name="GUBUN" value="5" readOnly />

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="사업자등록번호" className="form-label">사업자등록번호</label>
                            <input
                                id="사업자등록번호"
                                name="사업자등록번호"
                                type="text"
                                className="form-control"
                                value={formData.사업자등록번호}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="상호명" className="form-label">상호명</label>
                            <input
                                id="상호명"
                                name="상호명"
                                type="text"
                                className="form-control"
                                value={formData.상호명}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="대표자명" className="form-label">*대표자명</label>
                            <input
                                id="대표자명"
                                name="대표자명"
                                type="text"
                                className="form-control"
                                value={formData.대표자명}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="연락처" className="form-label">*연락처</label>
                            <input
                                id="연락처"
                                name="연락처"
                                type="text"
                                className="form-control"
                                value={formData.연락처}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="사업장주소" className="form-label">사업장주소</label>
                            <input
                                id="사업장주소"
                                name="사업장주소"
                                type="text"
                                className="form-control"
                                value={formData.사업장주소}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="가맹점ID" className="form-label">*가맹점ID</label>
                            <input
                                id="가맹점ID"
                                name="가맹점ID"
                                type="text"
                                className="form-control"
                                value={formData.가맹점ID}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="상태" className="form-label">상태</label>
                            <select
                                id="상태"
                                name="상태"
                                className="form-select w-full"
                                value={formData.상태}
                                onChange={handleChange}
                            >
                                <option value="1">중지</option>
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="수수료계산기준" className="form-label">수수료 계산기준</label>
                            <input
                                id="수수료계산기준"
                                name="수수료계산기준"
                                type="text"
                                className="form-control"
                                value={formData.수수료계산기준}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="수수료등록기준" className="form-label">수수료 등록기준</label>
                            <input
                                id="수수료등록기준"
                                name="수수료등록기준"
                                type="text"
                                className="form-control"
                                value={formData.수수료등록기준}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="정산은행" className="form-label">정산은행</label>
                            <select
                                id="정산은행"
                                name="정산은행"
                                className="form-select w-full"
                                value={formData.정산은행}
                                onChange={handleChange}
                            >
                                <option value="001">한국은행</option>
                                <option value="002">산업은행</option>
                                {/* Add other options here */}
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="취소기능" className="form-label">취소기능</label>
                            <input
                                id="취소기능"
                                name="취소기능"
                                type="text"
                                className="form-control"
                                value={formData.취소기능}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="예금주" className="form-label">예금주</label>
                            <input
                                id="예금주"
                                name="예금주"
                                type="text"
                                className="form-control"
                                value={formData.예금주}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="계좌번호" className="form-label">계좌번호</label>
                            <input
                                id="계좌번호"
                                name="계좌번호"
                                type="text"
                                className="form-control"
                                value={formData.계좌번호}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="세금계산서Email" className="form-label">세금계산서 Email</label>
                            <input
                                id="세금계산서Email"
                                name="세금계산서Email"
                                type="text"
                                className="form-control"
                                value={formData.세금계산서Email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="부가세표기" className="form-label">부가세표기</label>
                            <input
                                id="부가세표기"
                                name="부가세표기"
                                type="text"
                                className="form-control"
                                value={formData.부가세표기}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="결제공지" className="form-label">결제공지</label>
                            <input
                                id="결제공지"
                                name="결제공지"
                                type="text"
                                className="form-control"
                                value={formData.결제공지}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>수정</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: 'darkred', marginLeft: '10px', border: 'none' }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `가맹점리스트`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "TID", "결제구분", "지사명", "총판명", "대리점명", "가맹점명", "업종",
            "대표자명", "전화번호", "은행", "계좌번호", "주소", "등록날짜"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.결제구분 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.지사명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.업종 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대표자명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.전화번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.은행 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.계좌번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.주소 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록날짜 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Submerchants