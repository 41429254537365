import './css/common.css'
import './css/app.css'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

function Login() {
    let navigate = useNavigate();
    const [id, setId] = useState("")
    const [password, setPassword] = useState("")
    const [saveId, setSaveId] = useState(false)
    const [message, setMessage] = useState("")

    const onChangeId = (e) => {
        setId(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const onChangeSaveId = () => {
        setSaveId(e => !e)
    }

    useEffect(() => {
        let obj = localStorage.getItem('saveId')
        if (obj) {
            setId(obj)
            setSaveId(true)
        }
    }, [])

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/login", {
            email: id,
            password: password
        }, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                if (saveId) {
                    localStorage.setItem('saveId', id)
                }
                else {
                    localStorage.removeItem('saveId')
                }
                navigate('/dashboard')
            }
            else if (tmp?.code == 2001) {
                setMessage({ msg: "아이디가 존재하지 않습니다." })
            }
            else if (tmp?.code == 2002) {
                setMessage({ msg: "패스워드가 일치하지 않습니다." })
            }
            else {
                setMessage({ msg: "로그인에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    return (
        <div className="login" style={{padding:"0 2rem"}}>
            <MessageModal message={message} />
            <div className="container sm:px-10">
                <div className="block xl:grid grid-cols-2 gap-4">
                    <div className="hidden xl:flex flex-col min-h-screen">
                        <a href="" className="-intro-x flex items-center pt-5">
                            <img alt="Pay K Logo" src='/img/dl_logo_white.png' className="w-32" />
                        </a>
                        <div className="my-auto">
                            <img alt="Midone - HTML Admin Template" className="-intro-x w-1/2 -mt-16" src="/img/illustration.svg" />
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                빠른결제대행사  <br />
                                PG사 결제 관리자
                            </div>
                            <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">관리자 페이지</div>
                        </div>
                    </div>
                    <div className="h-screen xl:h-auto flex py-0 xl:py-0 my-0 xl:my-0">
                        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                            <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                                관리자 로그인
                            </h2>
                            <div className="intro-x mt-2 text-slate-400 xl:hidden text-center"> 빠른결제대행사 - 관리자 페이지</div>
                            <div className="intro-x mt-8">
                                <form>
                                    <input type="text" id="login-id" className="intro-x login__input form-control py-3 px-4 block" placeholder="아이디" value={id} onChange={onChangeId} />
                                    <input type="password" id="login-pw" autocomplete="off" className="intro-x login__input form-control py-3 px-4 block mt-4" placeholder="패스워드" value={password} onChange={onChangePassword} onKeyDown={handleKeyPress} />
                                </form>
                            </div>
                            <div className="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
                                <div className="flex items-center mr-auto">
                                    <input type="checkbox" className="form-check-input border mr-2" id="saveId" checked={saveId} onChange={onChangeSaveId} />
                                    <label className="cursor-pointer select-none">아이디 저장</label>
                                </div>
                            </div>
                            <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                <button id="login-btn" className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" onClick={() => onSubmit()}>
                                    로그인
                                </button>
                            </div>
                            <div className="intro-x mt-10 text-slate-600 dark:text-slate-500 text-center xl:text-left">
                                <a className="text-primary dark:text-slate-200" href="/terms">이용약관</a>&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;