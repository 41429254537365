import { useDispatch, useSelector } from 'react-redux';
import './css/common.css'
import './css/app.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, change_Favorite, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
    ArrowRightLeft, Star
} from 'lucide-react';

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';


function Favorite() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    let Favorite = useSelector((state) => { return state.Favorite })

    const [loading, setLoading] = useState(true)

    const handleToggleState = (index) => {
        const selectedCount = Favorite.filter(item => item.state === 1).length;

        if (Favorite[index].state === 0 && selectedCount >= 4) {
            return;
        }

        const newMenuList = Favorite.map(item => ({ ...item }));
        newMenuList[index].state = newMenuList[index].state === 0 ? 1 : 0;

        dispatch(change_Favorite(newMenuList))
    };


    useEffect(() => {
        dispatch(change_Current_Location("즐겨찾기 관리"))
        dispatch(changeHeaderTitle({
            mainTitle: "관리자메뉴",
            subTitle: "즐겨찾기 관리"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    return (
        <div className="content calendar" id="contents">
            {loading ? <LoadingModal /> : ""}
            <div className="intro-y box p-5 mt-5">
                <div className='favorite'>
                    <div className='favorite-box'>
                        <div className='favorite-left'>
                            <div className='favorite-left-box'>
                                <div className='favorite-top'>메뉴 리스트</div>
                                <div className='favorite-list' style={{
                                    border: Favorite.filter(item => item.state === 0).length == 0 ? "none" : "",
                                    borderBottom: Favorite.filter(item => item.state === 0).length == 18 ? "none" : ""
                                }}>
                                    {
                                        Favorite.map(function (a, i) {
                                            if (a.state == 0) {
                                                return (
                                                    <div key={i} className={`favorite-item`} onClick={() => handleToggleState(i)}>
                                                        <div>{a.name}</div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div key={i} className={`favorite-item active`} onClick={() => handleToggleState(i)}>
                                                        <div>{a.name}</div>
                                                        <div><Star className='icon-start' /></div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='favorite-center'>
                            <div className='favorite-circle'>
                                <ArrowRightLeft className='' />
                            </div>
                        </div>
                        <div className='favorite-left'>
                            <div className='favorite-left-box'>
                                <div className='favorite-top'>즐겨찾기 리스트</div>
                                <div className='favorite-list' style={{
                                    border: Favorite.filter(item => item.state === 1).length == 0 ? "none" : "",
                                    borderBottom: Favorite.filter(item => item.state === 1).length == 18 ? "none" : ""
                                }}>
                                    {
                                        Favorite.map(function (a, i) {
                                            if (a.state == 1) {
                                                return (
                                                    <div key={i} className={`favorite-item`} onClick={() => handleToggleState(i)}>
                                                        <div>{a.name}</div>
                                                        <div><Star className='icon-start' /></div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className='favorite-text'>* 즐겨찾기는 최대 4개까지 설정 가능합니다.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Favorite;