import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './css/calendar.css'
import moment from 'moment';
import { LoadingModal } from './component/modal/modal';
import { change_Current_Location, changeHeaderTitle } from '../store';
import { changeNum } from './common/function';
import { PanelRight } from 'lucide-react';

function SecurityCalendar() {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const today = new Date();
    const [date, setDate] = useState(today);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [listData, setListData] = useState([])
    const [detailBtn, setDetailBtn] = useState(false)

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
    };

    useEffect(() => {
        dispatch(change_Current_Location("보안 달력"))
        dispatch(changeHeaderTitle({
            mainTitle: "보안 달력",
            subTitle: ""
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const [value, setValue] = useState(new Date().getFullYear());
    const [holidays, setHolidays] = useState([]);

    useEffect(() => {
        // 공휴일 데이터를 가져오는 함수
        const fetchHolidays = async () => {
            const year = value;
            try {
                const response = await axios.get(
                    'https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo',
                    {
                        params: {
                            solYear: year, // 연도를 지정
                            ServiceKey: process.env.REACT_APP_HOLIDAY_APIKEY, // 발급받은 서비스 키
                            numOfRows: 100, // 한 번에 가져올 데이터 수
                            _type: 'json', // JSON 형식으로 응답 받기
                        },
                    }
                );

                const holidayList = response.data.response.body.items.item.map(item => ({
                    date: item.locdate.toString(), // 날짜를 처리 (YYYYMMDD 형식)
                    name: item.dateName, // 공휴일 이름
                }));

                setHolidays(holidayList); // 공휴일 데이터를 상태에 저장
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, [value]);

    const isHoliday = (date) => {
        return holidays.some(holiday => holiday.date === formatDate(date));
    };

    const getHolidayName = (date) => {
        const holiday = holidays.find(holiday => holiday.date === formatDate(date));
        return holiday ? holiday.name : null;
    };

    // 날짜 포맷을 YYYYMMDD 형식으로 변환하는 함수
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // 월을 2자리로
        const day = (`0${date.getDate()}`).slice(-2); // 일을 2자리로
        return `${year}${month}${day}`;
    };


    useEffect(() => {
        ListData()
    }, [])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/transaction/list", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    let arr = [
        { "date": "2024.09.02", "text1": "일정1", "text2": "일정2" },
        { "date": "2024.09.03", "text1": "일정1", "text2": null },
        { "date": "2024.09.04", "text1": "일정1", "text2": "일정2" },
        { "date": "2024.09.09", "text1": "일정1", "text2": "일정2" },
        { "date": "2024.09.20", "text1": "일정1", "text2": null },
        { "date": "2024.09.21", "text1": "일정1", "text2": "일정2" },
        { "date": "2024.09.22", "text1": "일정1", "text2": null },
        { "date": "2024.10.01", "text1": "일정1", "text2": null },
        { "date": "2024.10.02", "text1": "일정1", "text2": "일정2" },
        { "date": "2024.10.03", "text1": "일정1", "text2": "일정2" },
    ]

    return (
        <div className="content calendar" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <DetailInfo setModal={setModal} /> : ""}
            <div className="intro-y box p-5 mt-5">
                <div className='flex'>
                    <div style={{ width: detailBtn ? "calc(100% - 230px)" : "100%" }}>
                        <Calendar
                            value={date}
                            onChange={handleDateChange}
                            formatDay={(locale, date) => moment(date).format('D')}
                            formatYear={(locale, date) => moment(date).format('YYYY')}
                            formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}
                            calendarType="gregory"
                            showNeighboringMonth={true}
                            next2Label={null}
                            prev2Label={null}
                            minDetail="year"

                            // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                            activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                            onActiveStartDateChange={({ activeStartDate }) => {
                                setValue(activeStartDate.getFullYear())
                                setActiveStartDate(activeStartDate)
                            }
                            }

                            // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                            tileContent={({ date, view }) => {
                                let html = [];
                                if (view === 'month' && isHoliday(date)) {
                                    html.push(<div className='StyledEX-hoilday' onClick={() => setDetailBtn(true)}>
                                        <div className='StyledEX-holiday'>
                                            <div>{getHolidayName(date)}</div>
                                        </div>
                                    </div>);
                                }
                                if (view === "month") {
                                    for (let i = 0; i < arr.length; i++) {
                                        let tmp = arr[i]
                                        if (tmp.date === moment(date).format("YYYY.MM.DD")) {
                                            html.push(
                                                <div className='StyledEX' onClick={() => setDetailBtn(true)}>
                                                    {
                                                        tmp?.text1
                                                            ?
                                                            <div className='StyledEX-holiday StyledEX-4'>
                                                                <div>{tmp.text1}</div>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        tmp?.text2
                                                            ?
                                                            <div className='StyledEX-holiday StyledEX-4'>
                                                                <div>{tmp.text2}</div>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                }
                                return <>{html}</>;
                            }}
                        />
                    </div>
                    <div className='calendar-right' style={{ display: detailBtn ? "" : "none" }}>
                        <div className='calendar-right-text'>{moment(date).format('YYYY.MM.DD')}</div>
                        {
                            holidays.map(function (a, i) {
                                if (moment(date).format('YYYYMMDD') == a.date) {
                                    return (
                                        <div className='calendar-right-holiday'>
                                            {a.name}
                                        </div>
                                    )
                                }
                            })
                        }
                        {
                            arr.map(function (a, i) {
                                if (moment(date).format('YYYY.MM.DD') == a.date) {
                                    return (
                                        <>
                                            {
                                                a?.text1
                                                    ?
                                                    <div className='calendar-right-day' onClick={() => setModal(true)}>
                                                        <div>{a.text1}</div>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                            {
                                                a?.text2
                                                    ?
                                                    <div className='calendar-right-day' onClick={() => setModal(true)}>
                                                        <div>{a.text2}</div>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </>
                                    )
                                }
                            })
                        }
                    </div>

                    {/* 오늘 버튼 추가 */}

                    <ul className="calendar-category-wrap">
                        <div className='StyledDate' onClick={handleTodayClick}>Today</div>
                        <div className={`calendar-icon ${detailBtn ? "active" : ""}`} onClick={() => setDetailBtn(e => !e)}><PanelRight style={{ width: "18px" }} /></div>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function DetailInfo({ setModal }) {
    return (
        <div id="modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">상세내역</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body">
                            <div className="detail-form">
                                <table className="tbl-grid">
                                    <tbody>
                                        <tr className="border-btm">
                                            <th>정산일</th>
                                            <td data-id="SETTLEMENT_DATE"></td>
                                        </tr>
                                        <tr>
                                            <th>결제수단</th>
                                            <td>카드</td>
                                        </tr>
                                        <tr>
                                            <th>승인건수</th>
                                            <td><money data-id="CONFIRM_CNT">0</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>승인금액</th>
                                            <td><money data-id="CONFIRM_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소건수</th>
                                            <td><money data-id="CANCEL_CNT">0</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소금액</th>
                                            <td><money data-id="CANCEL_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr style={{ display: "none" }}>
                                            <th>계좌이체</th>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                        </tr>
                                        <tr style={{ display: "none" }}>
                                            <th>가상계좌</th>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                        </tr>
                                        <tr className="border-btm">
                                            <th>합계</th>
                                            <td><money data-id="TOTAL_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>지급금액</th>
                                            <td><money data-id="SETTLEMENT_AMT">0</money><span>원</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 modal_btn" id="settlement_btn">정산</button>

                        <button type="button" className="btn btn-primary w-20 modal_btn" id="confirm_btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecurityCalendar;