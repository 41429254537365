import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';
import { changeNum, convertDateFormat } from './common/function';


function SetoffPaylist() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(30)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("거래보류내역"))
        dispatch(changeHeaderTitle({
            mainTitle: "거래조회",
            subTitle: "거래보류내역"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
            setup: (picker) => {
                // 이벤트 리스너 등록
                picker.on('selected', (start, end) => {
                    setPreDate(start.format('YYYY/MM/DD'));
                    setNextDate(end.format('YYYY/MM/DD'));
                });
            }
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    // 영업자 포맷
    const select1 = ["전체", "지사", "총판", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 금액범위 포맷
    const [preRange, setPreRange] = useState("")
    const [nextRange, setNextRange] = useState("")

    const handlePreRange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // 숫자만 허용 (정규식을 이용하여 숫자 여부 체크)
            setPreRange(value);
        }
    };

    const handleNextRange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // 숫자만 허용
            setNextRange(value);
        }
    };

    // 결제상태 포맷
    const select3 = ["PG 구분", "다날"]

    const [selectedValue2, setSelectedValue2] = useState("PG 구분");

    const handleSelectChange2 = (event) => {
        const value = event.target.value;
        setSelectedValue2(value);
    };

    // 검색구분 포맷
    const select4 = ["매장명", "TID", "승인번호"]

    const [selectedValue3, setSelectedValue3] = useState("매장명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/transaction/hold-list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                salesCategory: selectedValue == "지사" ? 1 : selectedValue == "총판" ? 2 : selectedValue == "대리점" ? 3 : 0,
                salesValue: selectedText,
                amountStart: preRange,
                amountEnd: nextRange,
                businessType: selectedValue2 == "PG 구분" ? "" : selectedValue2,
                searchCategory: selectedValue3 == "매장명" ? 1 : selectedValue3 == "TID" ? 2 : selectedValue3 == "승인번호" ? 3 : 0,
                searchValue: selectedText3,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 12, "text": "승인번호", "width": "110px", "textAlign": "center" },
        { "no": 9, "text": "취소일자", "width": "180px", "textAlign": "center" },
        // { "no": 3, "text": "지사", "width": "150px", "textAlign": "left" },
        // { "no": 4, "text": "총판", "width": "150px", "textAlign": "left" },
        // { "no": 5, "text": "대리점", "width": "150px", "textAlign": "left" },
        { "no": 6, "text": "가맹점", "width": "150px", "textAlign": "left" },
        { "no": 11, "text": "승인금액", "width": "130px", "textAlign": "right" },
        // { "no": 7, "text": "종류", "width": "110px", "textAlign": "center" },
        { "no": 2, "text": "TID", "width": "120px", "textAlign": "center" },
        // { "no": 8, "text": "상태", "width": "120px", "textAlign": "center" },
        { "no": 10, "text": "승인일자", "width": "180px", "textAlign": "center" },
        { "no": 13, "text": "카드사", "width": "110px", "textAlign": "center" },
        { "no": 14, "text": "할부개월", "width": "100px", "textAlign": "center" },
        // { "no": 15, "text": "구분", "width": "120px", "textAlign": "left" },
        { "no": 16, "text": "결제정보", "width": "100px", "textAlign": "center" },
        // { "no": 17, "text": "영수증", "width": "100px", "textAlign": "center" },
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "80px", "textAlign": "left" },
                    { "no": 12, "text": data.cardAuthNo, "width": "110px", "textAlign": "center" },
                    { "no": 9, "text": data?.cancelType ? convertDateFormat(`${data.cancelTranDate} ${data.cancelTranTime}`) : "-", "width": "180px", "textAlign": "center" },
                    // { "no": 3, "text": data.salesHierarchy.find(item => item.level === 1)?.name ?? "", "width": "150px", "textAlign": "left" },
                    // { "no": 4, "text": data.salesHierarchy.find(item => item.level === 2)?.name ?? "", "width": "150px", "textAlign": "left" },
                    // { "no": 5, "text": data.salesHierarchy.find(item => item.level === 3)?.name ?? "", "width": "150px", "textAlign": "left" },
                    { "no": 6, "text": data.franchisee.name, "width": "150px", "textAlign": "left" },
                    { "no": 11, "text": `${changeNum(data.amount)}원`, "width": "130px", "textAlign": "right" },
                    // { "no": 7, "text": "D_LC7212", "width": "110px", "textAlign": "center" },
                    { "no": 2, "text": data.catId, "width": "120px", "textAlign": "center" },
                    // { "no": 8, "text": data?.returnMsg ?? "승인", "width": "120px", "textAlign": "center" },
                    { "no": 10, "text": convertDateFormat(`${data.tranDate} ${data.tranTime}`), "width": "180px", "textAlign": "center" },
                    { "no": 13, "text": data.cardName?.substring(0,2), "width": "110px", "textAlign": "center" },
                    { "no": 14, "text": data.quota == "00" ? "." : `${data.quota}개월`, "width": "100px", "textAlign": "center" },
                    // { "no": 15, "text": "비사업자PG", "width": "120px", "textAlign": "left" },
                    {
                        "no": 16,
                        "text": (
                            <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-archive w-4 h-4" style={{ color: "#0064ff" }}>
                                    <path d="M20 9v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9m16-5H4a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-10 9h4"></path>
                                </svg>
                            </div>
                        ),
                        "width": "100px",
                        "textAlign": "center"
                    },
                    // {
                    //     "no": 17,
                    //     "text": (
                    //         <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal1(true) }}>
                    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clipboard w-4 h-4" style={{ color: "#0a6e0a" }}>
                    //                 <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    //                 <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                    //             </svg>
                    //         </div>
                    //     ),
                    //     "width": "100px",
                    //     "textAlign": "center"
                    // },
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <PayInfo setModal={setModal} clickData={clickData} /> : ""}
            {modal1 ? <ReceiptInfo setModal={setModal1} clickData={clickData} /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">

                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {/* {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />} */}

                    {/* <SearchRange type={"금액범위"} preRange={preRange} handlePreRange={handlePreRange} nextRange={nextRange} handleNextRange={handleNextRange} /> */}

                    {/* {moreBtn && <SearchSelect type={"PG 구분"} selectData={select3} handleSelectChange={handleSelectChange2} selectedValue={selectedValue2} />} */}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => onSubmitSearch()} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">

                <div className="tabulator_sorting_wrap">
                    <CountSelect onChange={handleChange} maxFirstPage={maxFirstPage} />
                    <CountAmount tableBodyData={listData?.total ?? 0} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>

        </div>
    )
}

const formatDate = (dateString) => {
    if (dateString.length !== 14) {
        return "Invalid date string";
    }

    const year = dateString.slice(2, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hour = dateString.slice(8, 10);
    const minute = dateString.slice(10, 12);

    return `${year}-${month}-${day} ${hour}:${minute}`;
};

function PayInfo({ setModal, clickData }) {
    let date = clickData.createdAt.split('T')
    let date1 = date[1].split('.')

    return (
        <div id="payment-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">결제 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                            <input type="hidden" id="event_check" />
                            <div className="col-span-12 sm:col-span-6">
                                <label for="CARD_NAME" className="form-label">결제카드</label>
                                <input id="CARD_NAME" type="text" className="form-control" readonly="" data-id="CARD_NAME" value={clickData.cardName} />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="LEFT_AMT" className="form-label">금액</label>
                                <input id="LEFT_AMT" type="text" className="form-control" readonly="" data-id="CONFIRM_AMT" value={new Intl.NumberFormat('ko-KR').format(clickData.amount)} />
                            </div>
                            <div className="col-span-12">
                                <label for="TID" className="form-label">거래 아이디</label>
                                <input id="TID" type="text" className="form-control" readonly="" data-id="TID" value={clickData.tid} />
                            </div>
                            <div className="col-span-12">
                                <label for="PRODUCT_NAME" className="form-label">상품명</label>
                                <input id="PRODUCT_NAME" type="text" className="form-control" readonly="" data-id="PRODUCT_NAME" value={clickData.itemName} />
                            </div>
                            <div className="col-span-12">
                                <label for="ORDER_NO" className="form-label">결제번호</label>
                                <input id="ORDER_NO" type="text" className="form-control" readonly="" data-id="ORDER_NO" value={clickData.cardAuthNo} />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="MODAL_MID" className="form-label">터미널 아이디</label>
                                <input id="MODAL_MID" type="text" className="form-control" readonly="" data-id="MID" value={clickData.catId} />
                            </div>
                            {/* <div className="col-span-12 sm:col-span-6">
                                <label for="RECEIPT_NO" className="form-label">영수증 번호</label>
                                <input id="RECEIPT_NO" type="text" className="form-control" readonly="" data-id="RECEIPT_NO" value={clickData.billInfo} />
                            </div> */}
                            <div className="col-span-12 sm:col-span-6">
                                <label for="REGDATE" className="form-label">날짜</label>
                                <input id="REGDATE" type="text" className="form-control" readonly="" data-id="CONFIRM_DATE" value={formatDate(`${clickData.tranDate}${clickData.tranTime}`)} />
                            </div>

                            <div className="col-span-12 sm:col-span-6">
                                <label for="TRADE_STATUS_NAME" className="form-label">거래 상태</label>
                                <input id="TRADE_STATUS_NAME" type="text" className="form-control" readonly="" data-id="TRADE_STATUS_NAME" value={clickData?.returnMsg ?? "승인"} />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="USER_NAME" className="form-label">이름</label>
                                <input id="USER_NAME" type="text" className="form-control" readonly="" data-id="USER_NAME" value={clickData.userName} />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="USER_NO" className="form-label">결제 구분</label>
                                <input id="USER_NO" type="text" className="form-control" readonly="" data-id="USER_NO" value={clickData.serviceType === "KEYIN" ? "수기" : "단말기"} />
                            </div>
                            {/* <div className="col-span-12 sm:col-span-6">
                                <label for="CANCELMSG" className="form-label CANCELMSG">구분</label>
                                <select id="CANCELMSG" className="form-select w-full 2xl:w-full mt-2 sm:mt-0 CANCELMSG" value={clickData.cardName}>
                                    <option value="일반취소">일반 취소</option>
                                </select>
                            </div> */}
                        </div>
                    </form>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn w-20 modal_btn btn-danger cancel_btn">취소</button> */}
                        <button type="button" className="btn w-20 modal_btn close_btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ReceiptInfo({ setModal, clickData }) {
    let date = clickData.createdAt.split('T')
    let date1 = date[1].split('.')

    return (
        <div id="receipt-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty type-receipt receipt-modal-content">
                    <div className="modal-header receipt-modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">결제 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body receipt-modal-body">
                            <div className="box-sales" id="box-sales">
                                {
                                    // 취소시 취소 이미지
                                    clickData?.cancelType
                                        ?
                                        <div className="box-result" style={{ top: "50%", position: "absolute" }}>
                                            <div className="inner">
                                                <img src="/img/bg_cancel.png" alt="취소" />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div id="header">
                                    <h2>신용카드<br />매출전표</h2>
                                    <h1><img src="/img/dl_logo_white.png" alt="앤트페이" /></h1>
                                </div>
                                <div className="list-info-box">
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">카드번호</span>
                                                        <span className="right-text" data-id="CARD_NO">{clickData.cardNo}</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">카드종류</span>
                                                        <span className="right-text" data-id="CARD_NAME">{clickData.cardName}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래구분</span>
                                                        <span className="right-text" data-id="STATE_CD">{clickData?.returnMsg ?? "승인"}</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">할부개월</span>
                                                        <span className="right-text" data-id="CARD_SELL_MON">{clickData.quota == "00" ? "일시불" : `${clickData.quota}개월`}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래일시</span>
                                                        <span className="right-text" data-id="APP_DT" id="APP_DT">{`${date[0]} ${date1[0]}`}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">영수증번호</span>
                                                        <span className="right-text" data-id="RECEIPT_NO">{clickData.billInfo}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap type-2">
                                        <div className="gap">
                                            <div className="tt"><h3>구매자정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">구매자</span>
                                                        <span className="right-text" data-id="USER_NAME">{clickData.userName}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">승인번호</span>
                                                        <span className="right-text" id="APP_NO" data-id="APP_NO">{clickData.cardAuthNo}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">주문번호</span>
                                                        <span className="right-text" data-id="ORDER_NO">{clickData.orderId}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상품명</span>
                                                        <span className="right-text" data-id="PRODUCT_NAME">{clickData.itemName}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gap">
                                            <div className="tt"><h3>결제금액정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">과세금액</span>
                                                        <span className="right-text" id="PG_AMT_BEFORE">{Number(clickData.amount) - Math.floor(Number(clickData.amount) / 11)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">부가세</span>
                                                        <span className="right-text" id="PG_VAT_AMT">{Math.floor(Number(clickData.amount) / 11)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">면세금액</span>
                                                        <span className="right-text">0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">합계</span>
                                                        <span className="right-text" data-id="AMOUNT">{clickData.amount}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>이용상점정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상점명</span>
                                                        <span className="right-text" id="MER_NAME" data-id="MER_NAME">{clickData.franchisee.name}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">URL</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">이용문의<br /><span className="txt-s">(구매/취소/배송)</span></span>
                                                        <span className="right-text" id="MER_HP" data-id="MER_HP">010-1234-5678</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">대표자</span>
                                                        <span className="right-text" id="MER_CEO" data-id="MER_CEO">홍길동</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" id="MER_BIZ_ADDRESS" data-id="MER_BIZ_ADDRESS">서울특별시 강남구</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제서비스업체정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">결제대행사명</span>
                                                        <span className="right-text" data-id="NAME">주식회사 앤트페이먼츠</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">사업자번호</span>
                                                        <span className="right-text" data-id="COMP_NO">127-86-6****</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">대표자명</span>
                                                        <span className="right-text" data-id="CEO">당한민</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">가맹점번호</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" data-id="ADDR">인천광역시 연수구 인천타워대로 323, A동 21층 2107-2108호(송도동, 송도 센트로드)</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoFooter">
                                    부가가치세법 제46조 3항에 따라 신용카드 매출전표도 매입세금계산서로 사용할 수 있으며, 부가가치세법 제33조 2항에 따라 별도의 세금계산서를 교부하지 않습니다.
                                    <div className="box-btn">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button id="receipt_print_btn" type="button" className="btn" style={{ marginRight: "3px", backgroundColor: "#d2e4fb", fontWeight: "600" }}>저장</button>
                        <button type="button" className="btn w-20 modal_btn receipt_close_btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetoffPaylist;